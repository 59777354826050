import React, { useContext, useEffect, useState } from 'react';
import { Button, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MultipleSelectChip } from '../MultipleSelectChip';
import { AuthContext } from '../../routes/security';
import axios from 'axios';

import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { LeafletCenterGeometry } from '../../templates/Map/utils/leaflet-center-geometry';

function PaperComponent(props: PaperProps) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

interface FilterProps {
    openDialog: boolean; 
    handleClose: React.MouseEventHandler;
    map: any;
    toggleFilter?: Function;
}

export const Filter = ({
    openDialog,
    handleClose,
    map,
    toggleFilter
}:FilterProps) => {

    const { user } = useContext(AuthContext);

    const [complexInfo, setComplexInfo] = useState();
    const [farmsInfo, setFarmsInfo] = useState();
    const [wtgInfo, setWtgInfo] = useState();

    useEffect(()=>{

        const {
            accessToken
        } = user;

        /** Get Wind Turbines */
        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/aergs/sites/filter`, {
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        })
        .then(({data})=>{
            setWtgInfo(data)
        })
        .catch((e)=>{
            alert(`Error trying to get sites!`)
        })

        /** Get Farms */
        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/farms/filter`, {
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        })
        .then(({data})=>{
            setFarmsInfo(data)
        })
        .catch((e)=>{
            alert(`Error trying to get sites!`)
        })

        /** Get Complex */
        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/complex/filter`, {
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        })
        .then(({data})=>{
            setComplexInfo(data)
        })
        .catch((e)=>{
            alert(`Error trying to get sites!`)
        })

    }, [])

    if(!map && toggleFilter === undefined) {
        return (
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                Loading map...
            </Dialog>
        )
    }

    const {
        filter
    } = map;

    if(!filter)
        return <div></div>;

    const {
        complex,
        farms,
        wtg
    } = filter

    const centerFocusByLands = (e:any) => {
        
        if(
            map.filter.complex.length !== 0 || 
            map.filter.farms.length !== 0
        )
            axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/boundingbox/filter`,
            {
                filter: {
                    ...map.filter
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then((res)=>{

                const {
                    polygon
                } = res.data;

                const centerByGeometry = new LeafletCenterGeometry(map);

                centerByGeometry.centerByGeometry(polygon);

                handleClose(e);

            })
            .catch((error)=>console.log(error)); 
        else 
            alert(`Please select a complex or farms.`)

    }

    const centerFocusByWTG = (e:any) => {

        if(map.filter.wtg.length !== 0)
            axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/aergs/sites/filter`,
            {
                filter: {
                    ...map.filter
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then((res)=>{

                const {
                    polygon
                } = res.data;

                const centerByGeometry = new LeafletCenterGeometry(map);

                centerByGeometry.centerByGeometry(polygon);

                handleClose(e);

            })
            .catch((error)=>console.log(error)); 
        else 
            alert(`Please select a wind turbines`)

    }

    return(
        <Dialog
            open={openDialog}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
                Filter Entities
            </DialogTitle>
            <DialogContent
                style={{ 
                    display: 'flex',  
                    flexDirection: `column`,
                    alignContent: `center`,
                    textAlign: `center`,
                    maxHeight: `400px`
                }}
            >
                <DialogContentText>
                    Complex
                </DialogContentText>
                <DialogContentText>
                    {complexInfo?
                        <MultipleSelectChip 
                            title={"Complex"}
                            target = {"complex"}
                            toggleFilter={toggleFilter}
                            filter={complex}
                            map={map}
                            options={complexInfo}
                        />
                        :
                        <LinearProgress />
                    }
                </DialogContentText>
                {/* <DialogContentText>
                    Farms
                </DialogContentText>
                <DialogContentText>
                    {farmsInfo?
                        <MultipleSelectChip 
                            title={"Farms"}
                            target = {"farms"}
                            toggleFilter={toggleFilter}
                            filter={farms}
                            map={map}
                            options={farmsInfo}
                        />
                        :
                        <LinearProgress />
                    }
                </DialogContentText>
                <DialogContentText>
                    Wind Turbines
                </DialogContentText>
                <DialogContentText>
                    {wtgInfo?
                        <MultipleSelectChip 
                            title={"Wind Turbines"}
                            target = {"wtg"}
                            toggleFilter={toggleFilter}
                            filter={wtg}
                            map={map}
                            options={wtgInfo}
                        />
                        :
                        <LinearProgress />
                    }
                </DialogContentText> */}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
                <Button autoFocus onClick={centerFocusByLands}>
                    Center By Lands
                </Button>
                <Button autoFocus onClick={centerFocusByWTG}>
                    Center By WTG
                </Button>
            </DialogActions>
        </Dialog>
    )

}