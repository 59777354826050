import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: true;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
        tv: true;

    }
}

export const LightTheme = createTheme({
    typography: {
        fontFamily: "Montserrat"
    },
    palette: {
        mode: 'light',
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)'
        },
        primary: {
            main: '#00A7E1',
            dark: '#0098D6',
            light: '#42A5F5',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#006F44',
            dark: '#0E5D15',
            light: '#3EA036',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#D32F2F',
            dark: '#C62828',
            light: '#EF5350',
            contrastText: '#FFFFFF'
        },
        warning: {
            main: '#ED6C02',
            dark: '#E65100',
            light: '#FF9800',
            contrastText: '#FFFFFF'
        },
        info: {
            main: '#0288D1',
            dark: '#01579B',
            light: '#03A9F4',
            contrastText: '#FFFFFF'
        },
        success: {
            main: '#2E7D32',
            dark: '#1B5E20',
            light: '#4CAF50',
            contrastText: '#FFFFFF'
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            selected: 'rgba(0, 167, 225, 0.5)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            focus: 'rgba(0, 0, 0, 0.12)'
        },
        background: {
            paper: '#FFFFFF',
            default: '#FAF9F9',
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 375,
            tablet: 768,
            laptop: 1024,
            desktop: 1280,
            tv: 1536
        }
    }
});