// code with utils functons
// export const convertStatusFilesToText = (status:string) => {

//     if(status === 'v')
//         return 'Verificado';

//     if(status === 'rs')
//         return 'Com Ressalva';

//     if(status === 'ac')
//         return 'Com Ressalva Aceita pelo Cliente';

// }

export const parseFileToGetName = (name: string) => {
    let parsedName = name.split(']')
    return parsedName.slice(-1)[0];
}

export const parseFileToGetType = (name: string) => {
    let parsedName = name.split('[')
    let parsedType = parsedName[1].split(']')
    return parsedType[0];
}

export const parseFileToGetDate = (name: string) => {
    let parsedName = name.split('[')
    let sigla = parsedName[1].split(']')
    if (sigla[0] === 'ITR_REC' || 
        sigla[0] === 'CM' || 
        sigla[0] === 'COM' || 
        sigla[0] === 'CIT' || 
        sigla[0] === 'CIT_ORIGINAL' || 
        sigla[0] === 'CCDD10' || 
        sigla[0] === 'CCDD15' || 
        sigla[0] === 'CCDD20' || 
        sigla[0] === 'CCIR' || 
        sigla[0] === 'ITR' || 
        sigla[0] === 'ITR_CND' || 
        sigla[0] === 'ITR_DIAT' || 
        sigla[0] === 'ITR_DARF'|| 
        sigla[0] === 'ITR_DIAC' || 
        sigla[0] === 'ITR_CAFIR' || 
        sigla[0] === 'DITR') {
        let parsedDate = parsedName[2].split(']')
        if(parsedDate[0].includes('ANO')){
          let parsedYear = parsedDate[0].split('O')
          return parsedYear[1]
        } else return parsedDate[0]
    } else return '';    
}

export const parseTimeToGetDate = (name: string) => {
    let parsedName = name.split('T')
    let parsedDate = parsedName[0].split('-')
    return parsedDate.reverse().join('/');
}

export const parseTimeToGetHour = (name: string) => {
    let parsedName = name.split('T')
    let parsedTime = parsedName[1].split('.')
    return parsedTime[0] ;
}

export const getUTMWtg = (lat: number, long: number, option:number) => {
  var utmObj = require('utm-latlng');
  var utm = new utmObj('GRS 1980');
  // lat = lat+"S";
  // long = long+"E";
  let location = utm.convertLatLngToUtm(long, lat, 2);
  // console.log(lat,long);
  // console.log(location);
  if(option === 1 ) {
    let string: string = location.Easting+"E"
    return string;
  } else {
    let string: string = location.ZoneNumber+"S"
    return string;
  }
   
}

export const getUTMWtg2 = (lat: number, long: number, option:number) => {
  var utmObj = require('utm-latlng');
  var utm = new utmObj('GRS 1980');
  // lat = lat+"S";
  // long = long+"E";
  let location = utm.convertLatLngToUtm(long, lat, 2);
  // console.log(lat,long);
  // console.log(location);
  if(option === 1 ) {
    let string: string = location.Northing+"N"
    return string;
  } else {
    let string: string = location.ZoneNumber+"S"
    return string;
  }
   
}


export const getTypeOfFile = (cod: string) => {
    let typeArchive = '';
    let parsedName = cod.split('[')
    let parsedType = parsedName[1].split(']')
    let code = parsedType[0]
    let i = 0;
    for(i ;i<63;i++) {
        if(code === json[i].Nomenclatura){
            typeArchive = json[i].archiveType
            break
        }
    }
    return typeArchive;
}

var json = [
    
  {
    "Nomenclatura": "CM",
    "archiveType": "certidão da matrícula"
  },
  {
    "Nomenclatura": "COM",
    "archiveType": "Certidão de Ônus da Matrícula"
  },
  {
    "Nomenclatura": "CIT",
    "archiveType": "Certidão de Inteiro Teor"
  },
  {
    "Nomenclatura": "CIT_ORIGINAL",
    "archiveType": "Certidão de Inteiro Teor Original (Prop Mãe)"
  },
  {
    "Nomenclatura": "CCD10",
    "archiveType": "Certidão de Cadeia Dominial 10 anos"
  },
  {
    "Nomenclatura": "CCD15",
    "archiveType": "Certidão de Cadeia Dominial 15 anos"
  },
  {
    "Nomenclatura": "CCD20",
    "archiveType": "Certidão de Cadeia Dominial 20 anos"
  },
  {
    "Nomenclatura": "TP",
    "archiveType": "Título de Posse"
  },
  {
    "Nomenclatura": "PLANTA",
    "archiveType": "Planta de georreferenciamento"
  },
  {
    "Nomenclatura": "PLANTA_SIGEF",
    "archiveType": "Planta de georreferenciamento Sigef"
  },
  {
    "Nomenclatura": "PLANTA_SIMP",
    "archiveType": "Planta simplificada"
  },
  {
    "Nomenclatura": "CROQUI",
    "archiveType": "Croqui preliminar do desenvolvedor"
  },
  {
    "Nomenclatura": "MD",
    "archiveType": "Memorial Descritivo"
  },
  {
    "Nomenclatura": "MD_SIGEF",
    "archiveType": "Memorial Descritivo Sigef"
  },
  {
    "Nomenclatura": "MD_INT",
    "archiveType": "Memorial Descritivo da Área de Interesse"
  },
  {
    "Nomenclatura": "DRL",
    "archiveType": "Declaração de Respeito de Limites"
  },
  {
    "Nomenclatura": "PCA",
    "archiveType": "Planilha de Cálculo de Área"
  },
  {
    "Nomenclatura": "ART",
    "archiveType": "Anotação de Responsabilidade Técn."
  },
  {
    "Nomenclatura": "CPA",
    "archiveType": "Comprovante de Pagamento de ART"
  },
  {
    "Nomenclatura": "PTEC",
    "archiveType": "Peça Técnica"
  },
  {
    "Nomenclatura": "CAP",
    "archiveType": "Certidão de Ato Praticado"
  },
  {
    "Nomenclatura": "DP",
    "archiveType": "Declaração de Posse"
  },
  {
    "Nomenclatura": "LAVA",
    "archiveType": "Laudo de Avaliação"
  },
  {
    "Nomenclatura": "CCIR",
    "archiveType": "Recibo CCIR"
  },
  {
    "Nomenclatura": "ITR",
    "archiveType": "Recibo ITR/CND ITR"
  },
  {
    "Nomenclatura": "ITR_REC",
    "archiveType": "Recibo do ITR"
  },
  {
    "Nomenclatura": "ITR_CND",
    "archiveType": "CND do ITR"
  },
  {
    "Nomenclatura": "ITR_DIAT",
    "archiveType": "Documento de Informação e Apuração do ITR"
  },
  {
    "Nomenclatura": "ITR_DARF",
    "archiveType": "DARF do ITR"
  },
  {
    "Nomenclatura": "ITR_DIAC",
    "archiveType": "Documento de Informação Atualização Cadastral do ITR"
  },
  {
    "Nomenclatura": "ITR_CAFIR",
    "archiveType": ""
  },
  {
    "Nomenclatura": "DITR",
    "archiveType": "Declaração Anual do ITR"
  },
  {
    "Nomenclatura": "CAR_REC",
    "archiveType": "Recibo CAR"
  },
  {
    "Nomenclatura": "CAR_DEM",
    "archiveType": "Demonstrativo CAR"
  },
  {
    "Nomenclatura": "IBAMA_CTF",
    "archiveType": "Cadastro Técnico Federal IBAMA"
  },
  {
    "Nomenclatura": "TCDA",
    "archiveType": "Título do CDA"
  },
  {
    "Nomenclatura": "CEFIR",
    "archiveType": ""
  },
  {
    "Nomenclatura": "LTAI",
    "archiveType": "Laudo Técnico de Avaliação Imobiliária"
  },
  {
    "Nomenclatura": "TLTD",
    "archiveType": "Título de Legitimação de Terras Devolutas"
  },
  {
    "Nomenclatura": "TUOS",
    "archiveType": "Termo de uso e ocupação do solo"
  },
  {
    "Nomenclatura": "TIT_DOA",
    "archiveType": "Título de Doação"
  },
  {
    "Nomenclatura": "TIT_DOM",
    "archiveType": "Título de Domínio"
  },
  {
    "Nomenclatura": "TIT_DEF",
    "archiveType": "Título Definitivo"
  },
  {
    "Nomenclatura": "F_DC_CDA",
    "archiveType": "Formulario e Documentos Cadastrais CDA"
  },
  {
    "Nomenclatura": "EPCV",
    "archiveType": "Escritura Pública de Compra e Venda"
  },
  {
    "Nomenclatura": "E_PUBL_CV",
    "archiveType": "Escritura Pública de Compra e Venda"
  },
  {
    "Nomenclatura": "E_PART_CV",
    "archiveType": "Escritura Particular de Compra e Venda"
  },
  {
    "Nomenclatura": "E_PART_DOA",
    "archiveType": "Escritura Particular de Doação"
  },
  {
    "Nomenclatura": "EPFP",
    "archiveType": "Escritura Pública Formal de Partilha"
  },
  {
    "Nomenclatura": "CCV",
    "archiveType": "Contrato de Compra e Venda"
  },
  {
    "Nomenclatura": "RCV",
    "archiveType": "Recibo de Compra e VEnda"
  },
  {
    "Nomenclatura": "CDH",
    "archiveType": "Cessão de Direitos Hereditários"
  },
  {
    "Nomenclatura": "CRT_D",
    "archiveType": "Carta de Doação"
  },
  {
    "Nomenclatura": "CRT_AF",
    "archiveType": "Carta de Aforamento"
  },
  {
    "Nomenclatura": "AUT_PAS",
    "archiveType": "Autorização de Passagem"
  },
  {
    "Nomenclatura": "HIP",
    "archiveType": "Hipoteca da Propriedade"
  },
  {
    "Nomenclatura": "PJ_IBAMA",
    "archiveType": "Processo Judicial IBAMA da Propriedade"
  },
  {
    "Nomenclatura": "SPRA",
    "archiveType": "sentença de processo de retificação de área"
  },
  {
    "Nomenclatura": "NOT_LIC",
    "archiveType": "Notificação de Licenciamento"
  },
  {
    "Nomenclatura": "KML_PARC Parcial Nome da Fazenda.kml",
    "archiveType": "Kml da área parcial de interesse"
  },
  {
    "Nomenclatura": "PLANTA_DIVCESS",
    "archiveType": "Planta da propriedade dividida por cessionária"
  },
  {
    "Nomenclatura": "FR Nome Fazenda.xlsx",
    "archiveType": "Folha de Rosto"
  },
  {
    "Nomenclatura": "DUP",
    "archiveType": "Declaração de Utilidade Pública"
  },
  {
    "Nomenclatura": "CHKL_GEO",
    "archiveType": "Checklist para elaboração de Peças Técnicas de Georref."
  }

  ]
