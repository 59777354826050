import { Layers } from "../../../models/Layers";
import * as _ from 'lodash';
import { FlexLayer } from "./leaflet-flex-layers";
import { LandsMethods } from "./Lands/leaflet-lands-methods";
import { ContractsMethods } from "./Contracts/leaflet-contracts-methods";
import { WindTurbinesMethods } from "./WindTurbines/leaflet-wind-turbines-methods";

import firebase from 'firebase/app';
import 'firebase/auth';

import axios from "axios";

import $ from "jquery";
import { RegFundMethods } from "./RegFund/leaflet-lands-methods";

declare const L:any;

export interface GroupConfig {
    id: string|null; 
    name: string; 
}

export interface Config {
    layers: Array<string>;
    opacity: any; 
    groupOrder: Array<any>; 
}

interface UserSession{
    name: string, 
    phone: string,
    config: string; 
    layers: Array<any>
}

export class LeafletUserConfig {

    public map: any; 
    private uniqueLayers:any; 

    constructor(map:any, user?:UserSession){

        this.map = map;

        this.map.filter = {
            complex: [],
            farms: [],
            wtg: []
        }

        map.filter.changed = true;

        this.config(user);
        
    }

    public config(user?:UserSession) {

        const map = this.map; 

        if (user!==undefined && user?.config!==null){

            map._state = this.getUserConfig(user?.config); 

            this.uniqueLayers = user?.layers?user?.layers:[];

            this.setInitialStateBasemap();

            /** Adjust flex layers */
            this.createFlexLayers();

            /**Create all layers by the user config! */
            this.uniqueLayers.map((layer:Layers)=>{
                this.configLayer(layer.config);
                return true; 
            });

            map.setView(new L.LatLng(map._state.center.lat, map._state.center.lng), map._state.zoom); 

        } else {

            this.uniqueLayers = user?.layers?user?.layers:[];
            
            const defaultGroupOrder = [
                {
                    id: null,
                    name: "General"
                }, 
                {
                    id: "props",
                    name: "Lands"
                }, 
                {
                    id: "amb",
                    name: "Environmental"
                }, 
                {
                    id: "aneel",
                    name: "ANEEL/EPE"
                }, 
                {
                    id: "pjeol",
                    name: "Wind Project"
                }
            ];


            const filterByGroupId = (groupId:string|null) => {    
                return this.uniqueLayers.filter((layer:Layers) => {
                    return layer.group === groupId || (!layer.group && groupId === null);
                })
            }

            const defaultConfig = {
                zoom: 9,
                center: {
                    lat: -9.35241606,
                    lng: -41.70089724
                },
                layers: [],
                opacity: {},
                groupOrder: defaultGroupOrder,
                listOrder: this.uniqueLayers,
                groupedLayersOrder: {
                    null: filterByGroupId(null),
                    "props": filterByGroupId("props"),
                    "amb": filterByGroupId("amb"),
                    "aneel": filterByGroupId("aneel"),
                    "pjeol": filterByGroupId("pjeol")
                },
                groupOpened: {
                    null: false,
                    "props": false,
                    "amb": false, 
                    "aneel": false,
                    "pjeol": false
                },
                onlyActiveLayers: false,
                basemapId: "default", //Basemap
                onlyFavoriteLayers: false,
                favoriteLayers: []
            }

            map._state = defaultConfig;

            this.setInitialStateBasemap(); 

            /** Adjust flex layers */
            this.createFlexLayers();

            /**Create all layers by the user config! */
            this.uniqueLayers.map((layer:Layers)=>{
                this.configLayer(layer.config);
                return true; 
            });

            
            map.setView(new L.LatLng(defaultConfig.center.lat, defaultConfig.center.lng), defaultConfig.zoom);

        }

        map.on('moveend', this.saveUserConfig); 

    }

    public configLayer(configTxt:string) {

        if(configTxt!==undefined){

            const config = JSON.parse(configTxt);
        
            if(config?.tipo !== undefined){

                const{ tipo, ws, name } = config;

                if(ws!==undefined && ws!== ""){

                    switch(tipo){
                        case "wms":
                            this.createWmsLayer(ws, name, config?.minZoom);
                            break; 
                        case "flex": /** Verify this! Problems with hidrografia */
                            this.createWmsLayer(ws, name, config?.minZoom);
                            break; 
                        case "wts":
                            this.createTileLayer(ws, name, config?.minZoom);
                            break; 
                        default:
                            this.treatExceptions();

                    }

                } else {
                    /**For while do nothing! */
                } 

            } else if(this.config!==undefined){

                const{ ws, name } = config;

                if(ws!==undefined && ws!== ""){

                    this.createWmsLayer(ws, name, config?.minZoom);

                } else {
                    /**For while do nothing! */
                } 

            }
        }

    }

    private createWmsLayer(ws:string, name:string, minZoom?:number) {

        this.map.createPane(name);

        const wmsOptions = {
            token: "public",
            version: "1.1.1",
            format: "image/png",
            subdomains: "12345678",
            transparent:true,
            uppercase: true,
            tile: true,
            layers: `${ws}:${name}`,
            minZoom: minZoom,
            pane: name
        }

        const layer = L.tileLayer.wms(`${process.env.REACT_APP_PROXY_GEOSERVER}/wms?`,wmsOptions);

        layer.on('loading', (res:any) => {

            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').hide();
            }

            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').show();
            }

        })

        layer.on('load', (res:any) => {
            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
        })

        layer.on('tileerror', (res:any) => {
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').show();
            }
        })

        this.map[name] = this.map[name]?this.map[name]:layer;
        
        this.setInitialState(name);

    }

    private createTileLayer(ws:string, name:string, minZoom?:number) {

        this.map.createPane(name);

        const tmsOptions = { 
            subdomains: "12345678",
            attribution: "Casa dos Ventos",
            tms: true,
            minZoom: minZoom,
            type: 'png',
            pane: name
        }

        const layer = L.tileLayer(`${process.env.REACT_APP_PROXY_GEOSERVER}/wts/${ws}%3A${name}@EPSG%3A900913@png/{z}/{x}/{-y}.png?`, tmsOptions);

        layer.on('loading', (res:any) => {

            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').hide();
            }

            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').show();
            }

        })

        layer.on('load', (res:any) => {
            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
        })

        layer.on('tileerror', (res:any) => {
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').show();
            }
        })

        this.map[name] = this.map[name]?this.map[name]:layer;

        this.setInitialState(name);

    }

    private createFlexLayers() {

        /** Check if exist lands on the user views */
        const existLands = this.uniqueLayers.findIndex((layer:any)=> layer.txt_code === 'newprop')

        if(existLands!==-1){
            new FlexLayer('newprop', 
                {
                    map: this.map,
                    maxZoom: 10,
                    url: `${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry`,
                    columnGeometry: 'prop1Geom'
                },
                new LandsMethods()
            );
        }

        this.setInitialState('newprop');

        /** Check if exist contracts on the user views */
        const existCtrt = this.uniqueLayers.findIndex((layer:any)=> layer.txt_code === 'ctrt')

        if(existCtrt!==-1){
            new FlexLayer('ctrt', 
                {
                    map: this.map,
                    maxZoom: 10,
                    url: `${process.env.REACT_APP_WEB_SERVICE}/api/glebes/geometry`,
                    columnGeometry: 'prop1geom'
                },
                new ContractsMethods()
            );
        }

        this.setInitialState('ctrt');

        /**Check if exist wind turbines on the user views */
        const existAerg = this.uniqueLayers.findIndex((layer:any)=> layer.txt_code === 'aerg')

        if(existAerg!==-1){
            new FlexLayer('aerg', 
                {
                    map: this.map,
                    maxZoom: 11,
                    url: `${process.env.REACT_APP_WEB_SERVICE}/api/aergs/geometry`,
                    columnGeometry: 'aerg1Geom'
                },
                new WindTurbinesMethods()
            );
        }

        this.setInitialState('aerg');

        const existRegFund = this.uniqueLayers.findIndex((layer:any)=> layer.txt_code === 'regfund')

        if(existRegFund!==-1){
            new FlexLayer('regfund', 
                {
                    map: this.map,
                    maxZoom: 11,
                    url: `${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry/regfund`,
                    columnGeometry: 'prop1_geom'
                },
                new RegFundMethods()
            );
        }

        this.setInitialState('regfund');

    }

    private setInitialState(name:string) {

        if(this.map._state.opacity[name]!==undefined){
            this.map.getPane(name).style.opacity = this.map._state.opacity[name]; 
        }

    }

    private treatExceptions() {
        console.log("Treat exceptions!")
    }

    private async saveUserConfig(e:any){
        
        const token = await firebase.auth().currentUser?.getIdToken();

        const map = e.target; 

        const configTxt = JSON.stringify(map._state);

        map._state.zoom = map.getZoom();

        const center = map.getCenter();
        map._state.center.lat = center.lat; 
        map._state.center.lng = center.lng; 

        const basemapScreenId = map.base.options.id; 
        map._state.basemapId = basemapScreenId;

        axios.put(`${process.env.REACT_APP_AUTH_SERVICE}/api/users/session`, {
            config: configTxt
        },
        {
            headers: {
                authorization: `Bearer ${token}`
            }
        })

    }

    private setInitialStateBasemap() {

        /**Setting the initial basemap */
        this.map.base = this.map.baseLayersArray.find((base:any)=>base.options.id === this.map._state.basemapId);
        this.map.base.addTo(this.map);

        /**Setting all the opacity of baselayers */
        this.map.baseLayersArray.map((base:any)=>this.map._state.opacity[base.options.id] !== undefined && base.setOpacity(this.map._state.opacity[base.options.id]))

    }

    private getUserConfig(configTxt:string){

        const jsonConfig = JSON.parse(configTxt);

        return jsonConfig; 

    }

    public toggleFilter (value:Array<string>, target:'complex'|'farms'|'wtg', map:any) {

        const layerLands = map['newprop'];
        const layerCtrt = map['ctrt'];
        const layerWtg = map['aerg'];
        const layerRegFund = map['regfund'];

        switch(target) {
            case 'complex':

                map.filter.complex = value;
                /** Toggle layer on the map to update the informations */
                if(map.hasLayer(layerLands)){
                    map.removeLayer(layerLands);
                    map.addLayer(layerLands);
                }

                if(map.hasLayer(layerCtrt)){
                    map.removeLayer(layerCtrt);
                    map.addLayer(layerCtrt);
                }

                if(map.hasLayer(layerRegFund)){
                    map.removeLayer(layerRegFund);
                    map.addLayer(layerRegFund);
                }

                break;
            case 'farms': 

                map.filter.farms = value;
                /** Toggle layer on the map to update the informations */
                if(map.hasLayer(layerLands)){
                    map.removeLayer(layerLands);
                    map.addLayer(layerLands);
                }

                if(map.hasLayer(layerCtrt)){
                    map.removeLayer(layerCtrt);
                    map.addLayer(layerCtrt);
                }

                if(map.hasLayer(layerRegFund)){
                    map.removeLayer(layerRegFund);
                    map.addLayer(layerRegFund);
                }
                
                break;
            case 'wtg':

                map.filter.wtg = value;
                /** Toggle layer on the map to update the informations */
                if(map.hasLayer(layerWtg)){
                    map.removeLayer(layerWtg);
                    map.addLayer(layerWtg);
                }
                
                break;
        }

        map.filter.changed = true;
    }

}