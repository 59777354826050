import React, { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import { 
    Stack,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    List,
    ListItem,
    CircularProgress,
    Box,
    styled,
    Divider,
    Button,
    TextField,
    Select,
    MenuItem,
    Input,
    Checkbox,
    FormControlLabel,
    InputLabel,
    Snackbar,
    Alert,
    LinearProgress
} from '@mui/material';


import axios from 'axios';

import { 
    useNavigate 
} from 'react-router-dom';

import useQuery from '../../hooks/query';

import { Layers } from '../../models/Layers';
import { AuthContext } from '../../routes/admin';
import { Form, Formik } from 'formik';

import * as Yup from "yup";
import { Loading } from '../Loading';

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const CreateLayers = () => {

    const [message, setMessage] = useState<MessageFormat>({
        text: "",
        severity: "error"
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCloseMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(false);
    }

    const showMessage = ({text, severity}:MessageFormat) => {
        setErrorMessage(true);
        setMessage({
            text,
            severity
        });
    }

    const navigate = useNavigate();
    const context:any = useContext(AuthContext);

    const idValue = useQuery().get("id"); 
    console.log(idValue)
    const [error, setError] = useState(false);
    const edit = idValue === null || idValue === "" ? false : true; 

    const [layers, setLayers] = useState<Layers>();

    useEffect(()=> {
        
        if(edit && !layers){
            const endpoint = process.env.REACT_APP_AUTH_SERVICE + '/api/layers/'+idValue; 
            axios.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {

                    const data = response.data; 

                    setLayers(data)
                })
                .catch(function (error) {
                    setError(true);
                });
        }
        
    }, [])

    const editLayer = (body:any) => {
        axios.put(`${process.env.REACT_APP_AUTH_SERVICE}/api/layers/${idValue}`, body, {
                        headers: {
                            Authorization: `Bearer ${context.user?.accessToken}`
                        }
                    })
                    .then(function (response) {
                        showMessage({
                            text: "Layer saved successfully",
                            severity: 'success'
                        })
                    })
                    .catch(function (error) {
                        showMessage({
                            text: "Error trying to update layer",
                            severity: 'error'
                        })
                    });
        
    }

    const createLayer = (body:any) => {
        axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/layers`, body, {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    navigate(-1);
                })
                .catch(function (error) {
                    showMessage({
                        text: "Error trying to create layer",
                        severity: 'error'
                    })
                });

    }

    if(error){
        return(
            <Styled.Container>
                <Box sx={{ width: '100%' }} >
                    <Stack direction="row" spacing={2} id="top-tab-admin">
                        <Item id="tab-admin-title" elevation={0} >No layers found to edit</Item>
                    </Stack>
                </Box>
            </Styled.Container>
        )
    }

    if((edit && !layers )){
        return (
            <Styled.Container>
                <Box sx={{ width: '100%' }} >
                    <Stack direction="row" spacing={2} id="top-tab-admin">
                        <Item id="tab-admin-title" elevation={0} >
                            <LinearProgress />
                        </Item>
                    </Stack>
                </Box>
            </Styled.Container>
        )
    }

    const config = JSON.parse(layers?.config?layers?.config:"{}"); 

    return (
        <Styled.Container>
            <Box sx={{ width: '100%' }} >
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >{edit?"Edit":"Create"} Layer</Item>
                </Stack>
                <Paper elevation={3} id="form-admin">
                    {edit&&
                        <Grid container spacing={2}>
                            <Grid item xs id="info-role">
                                <Divider />
                                <Table>
                                    <TableBody>
                                        <TableRow id="info-content">
                                        <TableCell>
                                            <b>ID</b>
                                        </TableCell>
                                        <TableCell>
                                            {layers?.id}
                                        </TableCell>
                                        </TableRow>
                                        <TableRow id="info-content">
                                        <TableCell>
                                                <b>Created by</b>
                                        </TableCell>
                                        <TableCell>
                                            {layers?.created_by}
                                        </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    }
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            txt_code: layers?.txt_code?layers?.txt_code:"",
                            name: layers?.name?layers?.name:"",
                            group: layers?.group?layers?.group:"",
                            is_public: layers?.is_public?layers?.is_public:false,
                            is_active: layers?.is_active?layers?.is_active:false,
                            nameLayer: config?.name? config?.name:"",
                            tipo: config?.tipo?config?.tipo:"flex",
                            ws: config?.ws?config?.ws:"",
                            minZoom: config?.minZoom?config?.minZoom:0,
                            is_filterable: layers?.is_filterable?layers?.is_filterable:false,
                            filterable_column: layers?.filterable_column?layers?.filterable_column:""
                        }}
                        validateOnChange={false}
                        onSubmit={(values, actions) => {
                            const { 
                                txt_code,
                                name,
                                group,
                                is_public,
                                is_active,
                                is_filterable,
                                nameLayer,
                                tipo,
                                ws,
                                minZoom,
                                filterable_column
                            } = values;


                            const config = {
                                tipo,
                                ws,
                                minZoom,
                                name: nameLayer
                            }

                            if(!tipo)
                                delete config["tipo"];

                            if(!minZoom)
                                delete config["minZoom"];

                            if(!nameLayer)
                                delete config["name"];

                                console.log("Aqui")

                            const dataToSave = {
                                txt_code,
                                name,
                                group,
                                is_public,
                                is_active,
                                is_filterable,
                                filterable_column,
                                config: JSON.stringify(config)
                            };

                            if(edit)
                                editLayer(dataToSave);
                            else
                                createLayer(dataToSave)

                        }}
                        validationSchema={
                            Yup.object().shape({
                                txt_code: Yup.string().required("Required field."),
                                name: Yup.string().required("Required field."),
                                group: Yup.string().optional(),
                                is_public: Yup.boolean().required("Required field."),
                                is_active: Yup.boolean().required("Required field."),
                                tipo: Yup.string().optional(),
                                ws: Yup.string().required("Required field."),
                                minZoom: Yup.string().optional(),
                                nameLayer: Yup.string().optional(),
                                is_filterable: Yup.boolean().required("Required field."),
                                filterable_column: Yup.string().optional()
                            })
                        }
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur
                        }) => (
                            <Form 
                                noValidate
                            >
                                <TextField
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    id="txt_code"
                                    name="txt_code"
                                    className="txt-field"
                                    variant="outlined"
                                    label="Text Code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={layers?.txt_code}
                                    value={values.txt_code}
                                    helperText={
                                        errors.txt_code && touched.txt_code ? errors.txt_code : ""
                                    }
                                    error={errors.txt_code && touched.txt_code ? true : false}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    id="name"
                                    name="name"
                                    label="Name"
                                    className="txt-field"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={layers?.name}
                                    value={values.name}
                                    helperText={
                                        errors.name && touched.name ? errors.name : ""
                                    }
                                    error={errors.name && touched.name ? true : false}
                                />
                                <FormControlLabel
                                    control={   
                                        <Checkbox
                                            onChange={handleChange}
                                            id='is_public'
                                            name='is_public'
                                            value={values.is_public}
                                            sx={{
                                                height: 30,
                                                "&.Mui-checked": {
                                                    color: '#088BAE',
                                                }
                                            }}
                                        />
                                    }
                                    label="Is public?"
                                />
                                <FormControlLabel
                                    control={   
                                        <Checkbox
                                            onChange={handleChange}
                                            id='is_active'
                                            name='is_active'
                                            defaultChecked={values.is_active}
                                            value={values.is_active}
                                            sx={{
                                                height: 30,
                                                "&.Mui-checked": {
                                                    color: '#088BAE',
                                                }
                                            }}
                                        />
                                    }
                                    label="Is active?"
                                />
                                <FormControlLabel
                                    control={   
                                        <Checkbox
                                            onChange={handleChange}
                                            id='is_filterable'
                                            name='is_filterable'
                                            defaultChecked={values.is_filterable}
                                            value={values.is_filterable}
                                            sx={{
                                                height: 30,
                                                "&.Mui-checked": {
                                                    color: '#088BAE',
                                                }
                                            }}
                                        />
                                    }
                                    label="Is filterable?"
                                />
                                <div>
                                    <InputLabel><b>Group</b></InputLabel>
                                    <Select
                                        fullWidth
                                        id='group'
                                        name='group'
                                        value={values?.group?values?.group:0}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>General</MenuItem>
                                        <MenuItem value="aneel">ANEEL/EPE</MenuItem>
                                        <MenuItem value="amb">Environmental</MenuItem>
                                        <MenuItem value="props">Lands</MenuItem>
                                        <MenuItem value="pjeol">Wind Project</MenuItem>
                                    </Select>
                                </div>
                                <div>
                                    <InputLabel><b>Layer Type</b></InputLabel>
                                    <Select
                                        fullWidth
                                        id='tipo'
                                        name='tipo'
                                        value={values?.tipo}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="flex">Flex</MenuItem>
                                        <MenuItem value="wts">WTS</MenuItem>
                                        <MenuItem value="wms">WMS</MenuItem>
                                    </Select>
                                </div>
                                <TextField
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    id="ws"
                                    name="ws"
                                    label='Geoserver Workspace'
                                    className="txt-field"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={config?.ws}
                                    value={values.ws}
                                    helperText={
                                        errors.ws && touched.ws ? errors.ws : ""
                                    }
                                    error={errors.ws && touched.ws ? true : false}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    id="minZoom"
                                    name="minZoom"
                                    label='Min Zoom'
                                    className="txt-field"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={config.minZoom}
                                    value={values.minZoom}
                                    helperText={
                                        errors.minZoom && touched.minZoom ? errors.minZoom : ""
                                    }
                                    error={errors.minZoom && touched.minZoom ? true : false}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    id="nameLayer"
                                    name="nameLayer"
                                    label='Name Layer'
                                    className="txt-field"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={config.nameLayer}
                                    value={values.nameLayer}
                                    helperText={
                                        errors.nameLayer && touched.nameLayer ? errors.nameLayer : ""
                                    }
                                    error={errors.nameLayer && touched.nameLayer ? true : false}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    id="filterable_column"
                                    name="filterable_column"
                                    label='Filterable Column'
                                    className="txt-field"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={layers?.filterable_column}
                                    value={values.filterable_column}
                                    helperText={
                                        errors.filterable_column && touched.filterable_column ? errors.filterable_column: ""
                                    }
                                    error={errors.filterable_column && touched.filterable_column ? true : false}
                                />
                                <Button
                                    variant="contained"
                                    id='btn-next'
                                    className="btn-login"
                                    type='submit'
                                >
                                    {edit?"Edit":"Create"}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Paper>
                <Snackbar
                    open={errorMessage}
                    autoHideDuration={3000}
                    onClose={handleCloseMessage}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Alert onClose={handleCloseMessage} severity={message.severity} sx={{ width: '100%' }}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Box>
        </Styled.Container>
    );

}

