export const COLOR_REG_FUND:any = {
    'sc':'#f4cccc',
    'ct':'#93c47d',
    'qok':'#6d9eeb',
    'ok':'#b8860b'
}

export const MATR_STATUS = [
    {
        color: `#ff0000`,
        dashArray: '3 5',
        weight: 2
    },
    {
        color: `#006400`,
        dashArray: '',
        weight: 1
    }
]



