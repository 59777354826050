import styled, { css } from 'styled-components';

export const Resize = styled.div`
    ${({ theme }) => css`
        #tab-admin-title, #tab-admin-btn{
            font-size: ${theme.fonts.sizes.small};
            background: transparent; 
            padding-bottom: 10px;
        }
        
        #top-tab-admin{
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 0px;
            width: 400px; 
        }
        
        #username-details-admin{
            padding: 50px; 
        }

        #form-admin {
            /* font-size: ${theme.fonts.sizes.xsmall}; */
            margin: 20px; 
            margin-top: 50px;
            padding: 20px; 
        }
    `}

    .container {
        display: flex;
        /* Misc */
        border: 1px solid #cbd5e0;
        min-height: 70vh;
        width: 100%;
    }
    .container__left {
        /* Initially, the left takes 3/4 width */
        width: 40%;

        /* Misc */
        /* align-items: center; */
        display: flex;
        /* justify-content: center; */
    }
    .content {
        height: 28vh;
        overflow-y: auto;
    }
    .resizer {
        background-color: #cbd5e0;
        cursor: ew-resize;
        height: 350px;
        align-self: center;
        width: 5px;
    }
    .resizer:hover {
        background-color: #088BAE;
        opacity: 0.5;
    }
    .resizer:active {
        background-color: #088BAE;
        opacity: 0.8;
    }
    .container__right {
        /* Take the remaining width */
        flex: 1;

        /* Misc */
        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    .hide {
        opacity: 0;
      }
      
    .myDIV:hover + .hide {
        opacity: 1;
        display: block;
        color: grey;
      }

`;