import styled, { css } from 'styled-components';
import { LightTheme } from '../../styles/material-ui';

export const Container = styled.div`
    ${({ theme }) => css`

        .MuiListItemButton-root:hover{
            background: transparent;
        }

        .Mui-selected {
            background: transparent!important;
        }

        .Mui-selected > .MuiListItemText-root > span > a:after {
            content: '';
            position: absolute;
            bottom: -0.4rem;
            width: 50%;
            left: 25%;
            height: 0.2rem;
            background: ${LightTheme.palette.primary.main};
            transition: all 300ms ease-in-out;
        }

        a{
            color: ${theme.colors.dark};
            text-decoration: none; 
            font-family: ${theme.fonts.family.default}!important;
            display: block;
            position: relative;
        }

        a::after{
            content: '';
            position: absolute;
            bottom: -0.4rem;
            left: 50%;
            width: 0%;
            height: 0.2rem;
            background: ${LightTheme.palette.primary.main};
            transition: all 300ms ease-in-out;
        }

        a:hover:after{
            width: 50%;
            left: 25%;
        }
    `}


    span{
        text-align: center; 
        padding: 0;
        margin: 0;
    }
`;
