import { 
    useState,
    createContext,
    Context
} from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { 
    Toolbar
} from "@mui/material";

import AuthRoute from "./auth";
import PrivateRoute from "./security";

import { Map } from 'leaflet';

import { Header } from '../components/Header'; 
import { Sidebar } from '../components/Sidebar'; 
import { Main } from '../components/Main';

import { Sidebar as SidebarMap } from '../mapComponents/Sidebar';

import { Users } from '../templates/Users';
import { User } from '../templates/User';
import { Roles } from '../templates/Roles';
import { Views } from '../templates/Views';
import { CreateUsers } from '../templates/CreateUsers';
import { CreateRoles } from '../templates/CreateRoles';
import { CreateViews } from '../templates/CreateViews';
import { View } from '../templates/View';
import { Role } from '../templates/Role';
import { PageNotFound } from '../templates/PageNotFound';
import { Import } from '../templates/Import';

import { Home } from '../templates/Home';
import { MapComponent } from '../templates/Map';
import AdminRoute from './admin';
import { PageInConstruction } from '../templates/PageInConstruction';
import { ContactUs } from '../templates/ContactUs';
import { SwaggerAuth } from '../templates/SwaggerAuth';
import { SwaggerBIG } from '../templates/SwaggerBIG';
import { SwaggerImages } from '../templates/SwaggerImages';
import { DocumentPdf } from '../components/DocumentPdf';
import { Layers } from '../templates/Layers';
import { CreateLayers } from '../templates/CreateLayers';
import { Land } from '../templates/Land';

export const GlobalMapContext:Context<any> = createContext(undefined);

export const RoutesApplication = () => {

    const [open, setOpen] = useState(true);
    const [map, setGlobalMap] = useState<Map>(); //Is needed to set this to null!

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <Routes>
            <Route path="/login" element={<AuthRoute redirectTo="/home"/>} />
            <Route path="/" element={
                <PrivateRoute>
                    <Navigate to="/home"/>
                </PrivateRoute>
            } />
            <Route path="*" element={<PageNotFound/>} />
            <Route
                path="home"
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route
                path="map"
                element={
                    <PrivateRoute>
                        <GlobalMapContext.Provider value={map}>
                            <Header open={open} handleOpen={handleOpen}/>
                            <SidebarMap 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />
                                <MapComponent
                                    open={open}
                                    setGlobalMap={setGlobalMap}
                                />
                            </Main>
                        </GlobalMapContext.Provider>
                    </PrivateRoute>
                }
            />
            <Route
                path="contact"
                element={
                    <PrivateRoute>
                        <>
                            <Header 
                                open={false} 
                                handleOpen={handleOpen}
                                omitOpenButton={true}
                            />
                            <Main open={true}>
                                {/* <Toolbar /> */}
                                <ContactUs 
                                    context_locale={"EN"}
                                />
                            </Main>
                        </>
                    </PrivateRoute>
                }
            />
            <Route
                path="guide"
                element={
                    <PrivateRoute>
                        <>
                            <Header 
                                open={false} 
                                handleOpen={handleOpen}
                                omitOpenButton={true}
                            />
                            <Main open={true}>
                                <DocumentPdf 
                                    pdfUrl={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}/manual-cdv-gis/cdv-gis-basic-guide-v1.pdf`}
                                />
                            </Main>
                        </>
                    </PrivateRoute>
                }
            />
            <Route
                path="lands/:landId"
                element={
                    <PrivateRoute>
                        <>
                            <Header 
                                open={false} 
                                handleOpen={handleOpen}
                                omitOpenButton={true}
                            />
                            <Main open={true}>
                                <Land />
                            </Main>
                        </>
                    </PrivateRoute>
                }
            />
            <Route path="admin" element={
                <AdminRoute>
                    <Navigate to="/admin/users"/>
                </AdminRoute>
            }/>
            <Route path="admin/users" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <Users />
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/users/create" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <CreateUsers/>
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/users/:user" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <User />
                            </Main>
                        </>
                    </AdminRoute>
            }/>

            <Route path="admin/roles" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <Roles />
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/roles/create" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <CreateRoles/>
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/roles/:id" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <Role />
                            </Main>
                        </>
                    </AdminRoute>
            }/>

            <Route path="admin/views" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <Views />
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/views/create" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <CreateViews/>
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/views/:id" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <View />
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/layers" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <Layers />
                            </Main>
                        </>
                    </AdminRoute>
            }/>

            <Route path="admin/layers/create" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <CreateLayers />
                            </Main>
                        </>
                    </AdminRoute>
            }/>

            <Route path="admin/layers/create/:id" element={
                    <AdminRoute>
                        <>
                            <Header open={open} handleOpen={handleOpen}/>
                            <Sidebar 
                                open={open} 
                                handleDrawerClose={handleDrawerClose} 
                            />
                            <Main open={open}>
                                <Toolbar />  
                                <CreateLayers />
                            </Main>
                        </>
                    </AdminRoute>
            }/>
            <Route path="admin/import" element={
                <AdminRoute>
                    <>
                        <Header open={open} handleOpen={handleOpen}/>
                        <Sidebar 
                            open={open} 
                            handleDrawerClose={handleDrawerClose} 
                        />
                        <Main open={open}>
                            <Import />  
                        </Main>
                    </>
                </AdminRoute>
            }/>
            <Route path="admin/docs-auth" element={
                <AdminRoute>
                    <>
                        <Header open={open} handleOpen={handleOpen}/>
                        <Sidebar 
                            open={open} 
                            handleDrawerClose={handleDrawerClose} 
                        />
                        <Main open={open}>
                            <Toolbar />  
                            <SwaggerAuth />
                        </Main>
                    </>
                </AdminRoute>
            }/>
            <Route path="admin/docs-big" element={
                <AdminRoute>
                    <>
                        <Header open={open} handleOpen={handleOpen}/>
                        <Sidebar 
                            open={open} 
                            handleDrawerClose={handleDrawerClose} 
                        />
                        <Main open={open}>
                            <Toolbar />  
                            <SwaggerBIG />
                        </Main>
                    </>
                </AdminRoute>
            }/>
            <Route path="admin/docs-images" element={
                <AdminRoute>
                    <>
                        <Header open={open} handleOpen={handleOpen}/>
                        <Sidebar 
                            open={open} 
                            handleDrawerClose={handleDrawerClose} 
                        />
                        <Main open={open}>
                            <Toolbar />  
                            <SwaggerImages />
                        </Main>
                    </>
                </AdminRoute>
            }/>
        </Routes>       
    )

}
