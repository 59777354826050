export const TEXT_STATUS_REG_FUND= {
    'sc':'Contracting',
    'ct':'Contracted',
    'qok':'Ctr/Matr/CAR/CDU/Geo OK',
    'ok':'100% OK'
};

export const COLOR_REG_FUND:any = {
    'sc':'#f4cccc',
    'ct':'#93c47d',
    'qok':'#6d9eeb',
    'ok':'#b8860b'
}

export const MATR_STATUS = [
    {
        color: `#ff0000`,
        dashArray: '3 5',
        weight: 2
    },
    {
        color: `#006400`,
        dashArray: '',
        weight: 1
    }
]

const styleColor = 'display:inline-block;width:15px;height:15px;border-radius:3px;margin:0 4px;';

const styleBorda = `display:inline-block;width:15px;height:15px;margin:0 4px;`;

export const RegFundLegend = `
    <table cellpadding="8" border="0" width="100%">
        <tr>
            <td colspan="2" style="padding:2px;">
                <div style="background-color:lightgray;text-align:center;">
                    <b>Lands Regularization</b>
                </div>
            </td>
        </tr>
        ${Object.entries(TEXT_STATUS_REG_FUND).map((entries:Array<string>)=>{
            const index:any = entries[0];
            const text = entries[1];
            return (`<tr>
                        <td style="width: 5px;">
                            <div style="background-color:${COLOR_REG_FUND[index]};${styleColor}"></div>
                        </td>
                        <td>
                            ${text}
                        </td>
                    </tr>`
            );
        }).join('')}  
        <tr><td colspan="2"><b>Real State</b></td></tr>
        <tr><td><div style="background-color:rgba(255,255,255,0);border:${MATR_STATUS[1]['dashArray']===''?'solid':'dashed'} ${MATR_STATUS[1]['weight']}px ${MATR_STATUS[1]['color']};${styleBorda}"></div></td><td>With Real State</td></tr>
        <tr><td><div style="background-color:rgba(255,255,255,0);border:${MATR_STATUS[0]['dashArray']===''?'solid':'dashed'} ${MATR_STATUS[0]['weight']}px ${MATR_STATUS[0]['color']};${styleBorda}"></div></td><td>Without Real State</td></tr>
    </table>
`

// <tr><td colspan="2"><b>Desmembramento futuro</b></td></tr>
// <tr><td><div style="background-color:rgba(255,255,255,0);border:dashed 2px #ffff00;${styleBorda}"></div></td><td>Desmembr.futuro</td></tr>