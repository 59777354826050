import axios from "axios";

import firebase from 'firebase/app';
import 'firebase/auth';

import $ from 'jquery';

/**Adjust types later!
import { ContractsMethods } from "./Contracts/leaflet-contracts-methods";
import { LandsMethods } from "./Lands/leaflet-lands-methods";
import { WindTurbinesMethods } from "./WindTurbines/leaflet-wind-turbines-methods";
 */

declare const L:any; 

interface Options {
    map: any; 
    maxZoom: number;
    url: string; 
    columnGeometry: string; 
}

export class FlexLayer {

    private map:any;
    private id: string;
    private maxZoom: number; 
    private url:string; 

    private wmsLayer:any; 
    private geoJsonLayer:any; //Screen 

    private cachedGeoJsonLayer:any; //Cached

    private methods: any; 

    private columnGeometry: string; 

    constructor(id:string, {map, maxZoom, url, columnGeometry}:Options, methods:any){
        this.map = map;
        this.maxZoom = maxZoom;
        this.url = url;
        this.id = id; 
        this.columnGeometry = columnGeometry; 
        this.methods = methods; 

        this.config();
    }

    private config(){

        this.map.createPane(this.id);

        this.map[this.id] = new L.layerGroup([],{pane:this.id});
        this.geoJsonLayer = L.geoJSON([], {pane:this.id});  

        this.createWmsLayer('gtcver', this.id==='newprop'?'b4c_lands':this.id==='aerg'?'b4c_wtg':this.id==='regfund'?'newregfund':this.id);
        
        const methods = this.methods; 
        const pane = this.id; 

        this.cachedGeoJsonLayer = L.geoJSON([],{
            /**If the value isn't a point this function isn't executed! */
            pointToLayer: methods.iconTagByStatus,
            pane: pane
        })

        if(this.id === 'aerg') {
            this.map['aergGeojson'] = this.geoJsonLayer;
        }
        
        this.addFlexLayerToMap();
        
    }

    public async addFlexLayerToMap(){

        const id = this.id; 
        const map = this.map;
        const url = this.url;

        const wmsLayer = this.wmsLayer;
        const geoJSON = this.geoJsonLayer; 
        const featureGroup = this.map[this.id]; 

        const cacheGeoJSON = this.cachedGeoJsonLayer; 

        const maxZoom = this.maxZoom; 
        const methods = this.methods; 

        const columnGeometry = this.columnGeometry; 

        const updateFlexLayers = this.updateFlexLayers; 

        featureGroup.on('add', async function(){
            await updateFlexLayers(columnGeometry, map, wmsLayer, geoJSON, featureGroup, cacheGeoJSON, maxZoom, url, methods, id); 
        }); 
        
        map.on('moveend', async function(){

            if(map._state.layers.indexOf(id)!==-1){
                await updateFlexLayers(columnGeometry, map, wmsLayer, geoJSON, featureGroup, cacheGeoJSON, maxZoom, url, methods, id); 
            }

        }); 
        
    }

    private createWmsLayer(ws:string, name:string, minZoom?:number) {

        const wmsOptions = {
            token: "public",
            version: "1.1.1",
            format: "image/png",
            subdomains: "12345678",
            transparent:true,
            uppercase: true,
            layers: `${ws}:${name}`,
            pane: this.id
        }

        this.wmsLayer = L.tileLayer.wms(`${process.env.REACT_APP_PROXY_GEOSERVER}/wms?`,wmsOptions);

        this.wmsLayer.on('loading', (res:any) => {

            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').hide();
            }

            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').show();
            }

        })

        this.wmsLayer.on('load', (res:any) => {
            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
        })

        this.wmsLayer.on('tileerror', (res:any) => {
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').show();
            }
        })

    }

    public async updateFlexLayers(columnGeometry:any, map:any, wmsLayer:any, geoJSON:any, featureGroup:any, cacheGeoJSON:any, maxZoom:any,url:any, methods:any, idLayer:string){

        const zoom = map.getZoom();

        /**Refresh token */
        const token = await firebase.auth().currentUser?.getIdToken();

        wmsLayer._url = wmsLayer._url.split("?")[0]; 

        wmsLayer._url = wmsLayer._url.concat(`?access_token=${token}`);

        const {
            farms,
            complex,
            wtg
        } = map.filter;

        wmsLayer._url = wmsLayer._url.concat(`&farms=${farms.length>0?farms.join(','):null}`);
        wmsLayer._url = wmsLayer._url.concat(`&complex=${complex.length>0?complex.join(','):null}`);
        wmsLayer._url = wmsLayer._url.concat(`&wtg=${wtg.length>0?wtg.join(','):null}`);

        if(map.filter.changed){
            wmsLayer.setParams({updatedAt: Date.now()}, false);
            map.filter.changed = false;
        }
        
        if(zoom > maxZoom){

            if(!featureGroup.hasLayer(geoJSON)){
                featureGroup.addLayer(geoJSON)
            }
            if(featureGroup.hasLayer(wmsLayer)){
                featureGroup.removeLayer(wmsLayer)
            }    

            const bounds = map.getBounds();

            const data = (await axios.post(url, //new State
                {
                    minlat: bounds?.getSouthWest().lat.toPrecision(5),
                    minlng: bounds?.getSouthWest().lng.toPrecision(5),
                    maxlat: bounds?.getNorthEast().lat.toPrecision(5),
                    maxlng: bounds?.getNorthEast().lng.toPrecision(5),
                    filter: map.filter
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
                .then((res:any)=>(res.data.filter((obj:any)=>obj[columnGeometry]!==undefined)))
                .catch(()=> []))
                .map((data:any) => {
                    let dataFormatted = data;

                    if (typeof dataFormatted[columnGeometry] ==='string') {
                        dataFormatted[columnGeometry] = JSON.parse(dataFormatted[columnGeometry])
                    }

                    return dataFormatted;
                })
            
            
            /**Get features by data of bounding box*/
            const featuresByBoundingBox = methods.convertDataToFeatures(data);

            /**Get all the features already saved*/
            var featuresCached:any = [];
            cacheGeoJSON.getLayers().length >0 && cacheGeoJSON.eachLayer((layer:any)=>{
                featuresCached.push(layer.feature)
            })

            /**Calculate the layers that didn't cache */
            const layersNotCached = featuresByBoundingBox.filter((feature:any)=>{
                return !featuresCached.some(function(obj:any){
                    return obj.properties.id === feature.properties.id; 
                })
            })

            /**Create layer cache */
            if(layersNotCached.length > 0){
                cacheGeoJSON.addData(layersNotCached);
            }

            /**Add the layer already cache on the screen */
            cacheGeoJSON.getLayers().length > 0 && cacheGeoJSON.eachLayer((layer:any)=>{
                
                const id = layer.feature.properties.id; 

                const displayHasLayer = featuresByBoundingBox.some((feature:any)=>{
                    return feature.properties.id === id
                });

                const createStyle = methods.createStyleByFeature; 

                if(displayHasLayer){
                    if(!geoJSON.hasLayer(layer)){

                        if(createStyle!==undefined){
                            layer.setStyle(createStyle(layer.feature));
                        }

                        if(idLayer === 'newprop'){

                            const properties = layer.feature.properties; 

                            const htmlLegend = `
                                <div>
                                    <div style="padding:1px;"><b style="color:red;">${properties.id}</b> - <b>${properties.name}</b></div>
                                    <div><b>Owner:</b> ${properties.owner.split(";").join("</br>")}</div>

                                    ${Object.entries(properties.contracts).length > 0?
                                        `
                                        <div>  
                                            <b>Contracts:</b>
                                            ${Object.entries(properties.contracts).map(([key, value]:any, index)=>{
                                                const { txt } = value; 

                                                if(index === 0){
                                                    return ` ${txt} - Reg.: ${key} `; 
                                                } else {
                                                    return `| ${txt} - Reg.: ${key} `; 
                                                }
                                            }).join("<br/>")}
                                        </div>
                                    `
                                    :
                                    ""}
                                </div>
                            `;

                            layer.bindTooltip(htmlLegend, {sticky:true, permanent:false})
                        }

                        if(idLayer === 'ctrt'){

                            const properties = layer.feature.properties; 

                            const htmlLegend = `
                                <div>
                                    <div style="padding:1px;background-color:darkgray;color:white;"><b>${properties.id?properties.id:"Not defined"}</b></div>
                                    <div><b>Farm:</b> ${properties.name}</div>
                                    <div><b>Owner:</b> ${properties.owner.split(";").join("</br>")}</div>
                                    <div><b>Status:</b> ${properties.status}</div>
                                </div>
                            `;
                            
                            layer.bindTooltip(htmlLegend, {sticky:true, permanent:false})
                        }

                        if(idLayer === 'aerg'){
                            const properties = layer.feature.properties; 

                            const htmlLegend = `
                                <div>
                                    <div><b>${properties.maq}</b></div>
                                    <div><b>Complex:</b> ${properties.complex}</div>
                                    <div><b>Project:</b> ${properties.project}</div>
                                    <div><b>FC:</b> ${properties.fc} %</div>
                                </div>
                            `;
                            
                            layer.bindTooltip(htmlLegend.replace(/(\r\n|\n|\r)/gm, "").replace(/\s\s/g,''), { interactive: true, sticky: true, className: 'tooltip-aerg' })
                        }

                        if(idLayer === 'regfund') {

                            const properties = layer.feature.properties; 

                            const htmlLegend = `
                                <div>
                                    <div style="padding:1px;"><b style="color:red;">${properties.id}</b> - <b>${properties.name}</b></div>
                                    ${properties.owner? `<div><b>Owner:</b> ${properties.owner.split(";").join("</br>")}</div>`: ""}

                                    ${Object.entries(properties.contracts).length > 0?
                                        `
                                        <div>  
                                            <b>Contracts:</b>
                                            ${Object.entries(properties.contracts).map(([key, value]:any, index)=>{
                                                const { txt } = value; 

                                                if(index === 0){
                                                    return ` ${txt} - Reg.: ${key} `; 
                                                } else {
                                                    return `| ${txt} - Reg.: ${key} `; 
                                                }
                                            }).join("<br/>")}
                                        </div>
                                    `
                                    :
                                    ""}
                                </div>
                            `;
                            
                            layer.bindTooltip(htmlLegend.replace(/(\r\n|\n|\r)/gm, "").replace(/\s\s/g,''), { interactive: true, sticky: true, className: 'tooltip-aerg' })

                        }

                        layer.addTo(geoJSON);
                    }
                }else {
                    if(geoJSON.hasLayer(layer)){
                        geoJSON.removeLayer(layer);
                    }
                }
            }); 

        } else{

            if(featureGroup.hasLayer(geoJSON)){
                featureGroup.removeLayer(geoJSON);
            }
                        
            if(!featureGroup.hasLayer(wmsLayer)){
                featureGroup.addLayer(wmsLayer); 
            }
                        
        }

    }

}