import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../routes/security";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    ButtonProps,
    Paper,
    Grid,
    IconButton,
    Snackbar,
    Alert
} from '@mui/material';

import Draggable from 'react-draggable';
import { MultipleSelectChip, Option } from "../MultipleSelectChip";
import { CollapseComponent } from "../CollapseComponent";

import FeedIcon from '@mui/icons-material/Feed';
import { FileDownload } from '@mui/icons-material';

import CloudCircleIcon from '@mui/icons-material/CloudCircle';

import _ from 'lodash';

function PaperComponent(props:any) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

interface DialogSearchProps {
    open: boolean; 
    toggleFiles: ButtonProps["onClick"]; 
    map: any; 
}

const options:Array<Option> = [
    {
        id: 'owners',
        title: 'Owners'
    },
    {
        id: 'lands',
        title: 'Lands'
    },
    {
        id: 'contracts',
        title: 'Contracts'
    }
]

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

export const DialogFiles = ({
    open,
    toggleFiles,
    map
}:DialogSearchProps) => {

    const { user, sessionUser } = useContext(AuthContext);

    const [filesOwners, setFilesOwners] = useState<Array<any>>();
    const [filesLands, setFilesLands] = useState<Array<any>>();
    const [filesContracts, setFilesContracts] = useState<Array<any>>();

    const [filter, setFilter] = useState<Array<string>>([]); 

    const handleChange = (event:any) => {
        setFilter(event.target.value);
    };

    /**ERROR MESSAGES */
    const [message, setMessage] = useState<MessageFormat>({
        text: "",
        severity: "error"
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCloseErrorMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(false);
    }

    const showErrorMessage = ({text, severity}:MessageFormat) => {
        setErrorMessage(true);
        setMessage({
            text,
            severity
        });
    }

    useEffect(()=>{

        async function contentSearch (){
            axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/files/lands/user`,{
                filter: map?.filter
            },{
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then(({data})=>{
                setFilesLands(data);
            })
            .catch((error)=>showErrorMessage({
                text: `Error trying to get files of lands`,
                severity: 'error'
            })); 

            axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/files/owners/user`,{
                filter: map?.filter
            },{
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then(({data})=>{
                setFilesOwners(data);
            })
            .catch((error)=>showErrorMessage({
                text: `Error trying to get files of lands`,
                severity: 'error'
            })); 

            axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/files/contracts/user`,{
                filter: map?.filter
            },{
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then(({data})=>{
                setFilesContracts(data);
            })
            .catch((error)=>showErrorMessage({
                text: `Error trying to get files of lands`,
                severity: 'error'
            })); 
        }

        if(open && map?.filter){
            contentSearch();
        }

        return (()=> setFilesLands(undefined))

    }, [open, user?.accessToken, map?.filter])

    /**Checking permissions to download files and show checkbox */
    const permissions = sessionUser?.permissions?sessionUser?.permissions:[]; 

    const checkIfHasPermission = (target:string, resource:string, verb: string) => {
        return permissions?.findIndex((permission:any)=>{
            return permission === `${target}.${resource}.${verb}`; 
        }) !== -1; 
    }

    if(!map) {
        return <div>Loading map...</div>
    }

    const downloadFile = (target:'owners'|'contracts'|'lands', targetId: string, fileId:string) => {

        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/files/${target}/${targetId}/${fileId}`, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then (({data})=>{

            const {
                signed_url
            } = data;

            window.open(signed_url, "_blank")

        })
        .catch((e)=>showErrorMessage({
            text: `File not found!`,
            severity: 'warning'
        }))

    }

    return (
        <>
            <Dialog
                open={open}
                onClose={toggleFiles}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <b>Search Files</b> &nbsp;<CloudCircleIcon sx={{fontSize: 18, marginLeft: -1}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <MultipleSelectChip
                                title={"Filter"}
                                name={"Filter"}
                                options={options}
                                onChange={handleChange}
                                value={filter}
                                error={false}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: 500,
                        maxHeight: 500
                    }}
                >
                {filter.indexOf('lands')!==-1&&
                    <CollapseComponent
                        title = {"Lands"}
                        children={
                            <DialogContentText>
                                {filesLands && filesLands.length>0?
                                    filesLands.map((file:any)=>{
                                        return(
                                            <tr key={`lands-${file.prop1_cod}-btnfile-${file.arquivo_id}`}>
                                                <td
                                                    style={{
                                                        width:400
                                                    }}
                                                >
                                                    <div>  
                                                        <div>
                                                            <FeedIcon sx={{fontSize: 15, padding: 0, color: 'black'}}/> &nbsp; 
                                                            <b style={{fontSize: '0.8em', color: 'black'}}>{`${file.prop1_cod} - ${file.nome_arquivo?file.nome_arquivo:"Não definido"}`}</b>
                                                        </div>
                                                        <span style={{fontSize: '0.6em', lineHeight: '0.6em', color: 'gray'}}>{`Status: ${file.status?file.status:"Não definido"}`}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {checkIfHasPermission('lands', 'files', 'download') &&
                                                        <IconButton
                                                            id={`lands-${file.prop1_cod}-btnfile-${file.arquivo_id}`}
                                                            onClick={(e:any)=> downloadFile('lands', file.prop1_cod, file.arquivo_id)}
                                                        > 
                                                            <FileDownload 
                                                                id={`lands-${file.prop1_cod}`}
                                                                sx={{fontSize: 15}}
                                                            />
                                                        </IconButton> 
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    'No results found'
                                }
                            </DialogContentText>
                        }
                        defaultState={false}
                    />
                }
                {filter.indexOf('contracts')!==-1&&
                    <CollapseComponent
                        title = {"Contracts"}
                        children={
                            <DialogContentText>
                                {filesContracts && filesContracts.length>0?
                                    filesContracts.map((file:any)=>{
                                        return(
                                            <tr key={`contracts-${file.ctrt1_cod}-btnfile-${file.arquivo_id}`}>
                                                <td
                                                    style={{
                                                        width:400
                                                    }}
                                                >
                                                    <div>  
                                                        <div>
                                                            <FeedIcon sx={{fontSize: 15, padding: 0, color: 'black'}}/> &nbsp; 
                                                            <b style={{fontSize: '0.8em', color: 'black'}}>{`${file.ctrt1_cod} - ${file.nome_arquivo?file.nome_arquivo:"Não definido"}`}</b>
                                                        </div>
                                                        <span style={{fontSize: '0.6em', lineHeight: '0.6em', color: 'gray'}}>{`Status: ${file.status?file.status:"Não definido"}`}</span>
                                                    </div>
                                                </td>
                                                <td id={`contracts-${file.ctrt1_cod}-btnfile-${file.arquivo_id}`}>
                                                    {checkIfHasPermission('contracts', 'files', 'download') &&
                                                        <IconButton
                                                            id={`contracts-${file.ctrt1_cod}-btnfile-${file.arquivo_id}`}
                                                            onClick={(e:any)=> downloadFile('contracts',file.ctrt1_cod,file.arquivo_id)}
                                                        > 
                                                            <FileDownload 
                                                                id={`contracts-${file.ctrt1_cod}`}
                                                                sx={{fontSize: 15}}
                                                            />
                                                        </IconButton> 
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    'No results found'
                                }
                            </DialogContentText>
                        }
                        defaultState={false}
                    />
                }
                {filter.indexOf('owners')!==-1&&
                    <CollapseComponent
                        title = {"Owners"}
                        children={
                            <DialogContentText>
                                {filesOwners && filesOwners.length>0?
                                    filesOwners.map((file:any)=>{
                                        return(
                                            <tr key={`owners-${file.prio1_cod}-btnfile-${file.arquivo_id}`}>
                                                <td
                                                    style={{
                                                        width:400
                                                    }}
                                                >
                                                    <div>  
                                                        <div>
                                                            <FeedIcon sx={{fontSize: 15, padding: 0, color: 'black'}}/> &nbsp; 
                                                            <b style={{fontSize: '0.8em', color: 'black'}}>{`${file.prio1_cod} - ${file.nome_arquivo?file.nome_arquivo:"Não definido"}`}</b>
                                                        </div>
                                                        <span style={{fontSize: '0.6em', lineHeight: '0.6em', color: 'gray'}}>{`Status: ${file.status?file.status:"Não definido"}`}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {checkIfHasPermission('owners', 'files', 'download') &&
                                                        <IconButton
                                                            id={`owners-${file.prio1_cod}-btnfile-${file.arquivo_id}`}
                                                            onClick={()=> downloadFile('owners', file.prio1_cod, file.arquivo_id)}
                                                        > 
                                                            <FileDownload 
                                                                id={`owners-${file.prio1_cod}`}
                                                                sx={{fontSize: 15}}
                                                            />
                                                        </IconButton> 
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    'No results found'
                                }
                            </DialogContentText>
                        }
                        defaultState={false}
                    />
                }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={toggleFiles}>
                        Close
                    </Button>
                </DialogActions>
                <Snackbar
                    open={errorMessage}
                    autoHideDuration={3000}
                    onClose={handleCloseErrorMessage}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Alert onClose={handleCloseErrorMessage} severity={message.severity} sx={{ width: '100%' }}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Dialog>
        </>
    )

}