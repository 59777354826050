import { Group } from ".";

export class LeafletGroupLayers {

    private map:any; 

    constructor(map:any){
        this.map = map; 
    }

    public getGroupLayers () {
        return this.map._state.groupOrder; 
    }

    public setGroupLayers (groupOrder:Array<Group>){
        this.map._state.groupOrder = groupOrder; 

        this.setZIndex();
    }

    public setZIndex (){

        /**This controller only deal with group items of menu */
        this.map._state.groupOrder.map((group:Group)=>{

            const groupId:any = group.id

            this.map._state.groupedLayersOrder[groupId].map((layer:any)=>{

                const txtCode = layer?.txt_code; 
                const config = JSON.parse(layer.config);

                /**Index inside a group! */
                const indexInsideGroup = this.map._state.groupedLayersOrder[groupId].findIndex((layer:any)=> layer.txt_code === txtCode);

                /**Index of group! */
                const indexOfGroup = this.map._state.groupOrder.findIndex((group:any)=> group.id === groupId);
                
                /**Array of ordened lengths of groups */
                const ordenedLengths = this.map._state.groupOrder.map((group:any)=>{
                    return this.map._state.groupedLayersOrder[group.id].length;
                });
                                        
                /**Acummulate only the values in range of a index group */
                const accumulatedValues = ordenedLengths.slice(0, indexOfGroup+1);
                
                /**Sum all the values of an array of numbers */
                const sumAccumulatedValues = accumulatedValues.reduce((accum:number, a:number)=>accum + a);
                
                /**Length of group */
                const lengthOfGroup = this.map._state.groupedLayersOrder[groupId].length;
                
                /**Calculate the zIndex of layers */
                const zIndex = 400 - sumAccumulatedValues + ( lengthOfGroup - indexInsideGroup );

                switch (txtCode){

                    case "ctrt":
                        this.map.getPane('ctrt').style.zIndex = zIndex;
                        break;
                    case "newprop":
                        /**Set the zIndex value by value of pane*/
                        this.map.getPane('newprop').style.zIndex = zIndex;
                        break;
                    case "aerg":
                        /**Set the zIndex value by value of pane*/
                        this.map.getPane('aerg').style.zIndex = zIndex;
                        break;
                    case "regfund":
                        /**Set the zIndex value by value of pane*/
                        this.map.getPane('regfund').style.zIndex = zIndex;
                        break;
                    case "midia":
                        console.log("Treat exception!");
                        break;
                    default:    
                        /**Set the zIndex value by value of pane*/
                        if(this.map.getPane(config.name)!==undefined){
                            this.map.getPane(config.name).style.zIndex = zIndex;
                        }
                }

                return true;

            })

            return true; 
        })
    }

}