import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
        personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

interface Item {
  id: string;
  title: string;
}

interface MultipleSelectChipProps {
  target: "farms" | "complex" | "wtg";
  toggleFilter: any;
  filter: any;
  map: any;
  options: Array<Item>;
  title: string;
}

export const MultipleSelectChip = ({
  target,
  toggleFilter,
  filter,
  map,
  options,
  title
}:MultipleSelectChipProps) => {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState<string[]>(map.filter[target]);

    const handleChange = (event: SelectChangeEvent<typeof filter>) => {
      const {
        target: { value },
      } = event;

      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      toggleFilter(value, target, map)
    };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip color='secondary' key={value} label={options.find((x) => x.id === value)?.title} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map(({id, title}) => (
            <MenuItem
              key={id}
              value={id}
              style={getStyles(title, personName, theme)}
            >
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}