import axios from "axios";
import { LatLngExpression } from "leaflet";
import { 
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { 
    Popup, 
    useMap 
} from "react-leaflet";

import { AuthContext } from "../../routes/security";

import './styles.css';
import { transformInfoOnHtml } from "./utils";


import DownloadIcon from '@mui/icons-material/Download';
import $ from "jquery";
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from "@mui/material";
import { theme } from "../../styles/theme";

export const Info = () => {

    const context:any = useContext(AuthContext);

    const parentMap:any = useMap();

    const [data, setData] = useState<any>(); 
    const [position, setPosition] = useState<LatLngExpression>();
    const [activeLayers, setActiveLayers] = useState<Array<string>>();

    /** States to check if the listener was added */
    const [listenerMap, setListenerMap] = useState<boolean>(false);
    const [listenerMapWTG, setListenerMapWTG] = useState<boolean>(false);
 
    const sessionUser:any = context?.sessionUser;  

    const permissions = sessionUser?.permissions?sessionUser?.permissions:[]; 

    const checkIfHasPermission = (target:string, resource:string, verb: string) => {
        return permissions?.findIndex((permission:any)=>{
            return permission === `${target}.${resource}.${verb}`; 
        }) !== -1; 
    }

    const checkIfCanShowInfo = () => {

        if(parentMap){
            return !parentMap?.lineRuler && !parentMap?.circleRuler && !parentMap?.zoomByWindowIn && !parentMap?.zoomByWindowOut
        } else {
            return false; 
        }

    }

    const onClick = useCallback(async (e) => {
        
        if(position!==null&&!parentMap?.info&&context.user?.accessToken && checkIfCanShowInfo()){

            const map = e.target.options?.pane === 'aerg'? e.target._map : e.target; 
            const activeLayers = map._state.layers; 
            const size = map.getSize();
            const bounds = map.getBounds().toBBoxString();
            const point = map.latLngToContainerPoint(e.latlng, parentMap.getZoom());
            const zoom = parentMap.getZoom();

            /**Removing poladm to don't repeat informations about "Municípios" */
            const indexPolAdm = activeLayers.indexOf('poladm'); 
            if(indexPolAdm > -1){
                activeLayers.splice(indexPolAdm, 1)
            }

            setActiveLayers(activeLayers);
            setPosition(e.latlng);

            axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/layers/info`, {
                lat: e.latlng.lat,
                lng: e.latlng.lng,
                layers: activeLayers,
                x: point.x.toFixed(0),
                y: point.y.toFixed(0),
                height: size.y,
                width: size.x,
                zoom: zoom, 
                bbox: bounds.replaceAll(",", "%2C"),
                filter: parentMap.filter
            },
            {
                headers: {
                    Authorization: `Bearer ${context.user?.accessToken}`
                }
            })
            .then((res)=>setData(res.data))
            .catch((e)=>console.log(e));
            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.user?.accessToken]);

    useEffect(() => {
        if(parentMap&&!listenerMap){
            parentMap.on('click', onClick);
            setListenerMap(true);
        }

        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentMap, onClick]);

    useEffect(()=> {
        /**Add event listener when the layer is a wind turbine */
        if(parentMap?.aergGeojson&&!listenerMapWTG){
            parentMap?.aergGeojson.on('click', onClick)
            setListenerMapWTG(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentMap?.aergGeojson])

    const [downloading, setDownloading] = useState(false);

    const handleCheckbox = (e:any) =>{

        const filesToDownload = [];

        if($('#checkbox-file-lands').is(":checked"))
            filesToDownload.push('lands');

        if($('#checkbox-file-contracts').is(":checked"))
            filesToDownload.push('contracts');

        if($('#checkbox-file-owners').is(":checked"))
            filesToDownload.push('owners');

        if(filesToDownload.length === 0) {
            $('#message-download').show();
        } else {
            $('#message-download').hide();
        }
        
    }

    const downloadZipRequest = () => {

        const filesToDownload = [];

        if($('#checkbox-file-lands').is(":checked"))
            filesToDownload.push('lands');

        if($('#checkbox-file-contracts').is(":checked"))
            filesToDownload.push('contracts');

        if($('#checkbox-file-owners').is(":checked"))
            filesToDownload.push('owners');

        if($('#leaflet-tile-loading').length > 0){
            $('#leaflet-tile-loading').show();
        }

        axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/files`,{
            position: position,
            files: filesToDownload
        },
        {
            headers: {
                Authorization: `Bearer ${context.user?.accessToken}`
            }
        })
        .then((res) => {
            /**Changing the text on the button */
            $('#text-download').show();
            $('#text-generate').hide();

            $('#loading-box').hide();

            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').hide();
            }

            return res.data;
        })
        .then((data) => {
            const { url } = data; 

            window.open(url, '_blank'); 
            setDownloading(false);
        })
        .catch((e)=>{ 
            
            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').show();
            }

            setDownloading(false);
            $('#message-download-failed').show();
            // alert(e)
        });

    }

    const handleDownloadZip = () => {

        setDownloading(true);
        $('#download-files').prop('disabled', true)
        /**Changing the text on the button */
        $('#message-download-failed').hide();
        $('#text-download').hide();
        $('#text-generate').show();

        /**Showing download progress */
        $('#loading-box').show();
        $('#download-progress').css('width', '12px');
        $('#download-progress').css('height', '12px');

        if(!downloading){
            downloadZipRequest();
        }
    };

    
    const downloadKml = checkIfHasPermission('lands', 'kmz', 'download')?
        (e:any) => {

            const code = e.target.id.split('-')[2];

            const layer = e.target.id.split('-')[1]

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry/kml/${layer ==='regfund'?'regfund/':''}${code}`,{
                headers: {
                    Authorization: `Bearer ${context.user?.accessToken}`
                }
            })
            .then((res)=>{
                return res.data;
            })
            .then((data)=>{
                const { url } = data; 

                window.open(url, '_blank'); 
            })
            .catch((e)=>{
                alert(e)
            });
        }
        : 
        undefined; 

    const downloadShape = checkIfHasPermission('lands', 'kmz', 'download')?
        (e:any) => {
            const code = e.target.id.split('-')[2];

            const layer = e.target.id.split('-')[1]

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry/shapefile/${layer ==='regfund'?'regfund/':''}${code}`,{
                headers: {
                    Authorization: `Bearer ${context.user?.accessToken}`
                }
            })
            .then((res)=>{
                return res.data;
            })
            .then((data)=>{
                const { signed_url } = data; 

                window.open(signed_url, '_blank'); 
            })
            .catch((e)=>{
                alert(e)
            });
        }
        : 
        undefined; 

    const noPermissionsToDownload = !checkIfHasPermission('lands', 'files', 'download') 
                                    && !checkIfHasPermission('contracts', 'files', 'download') 
                                    && !checkIfHasPermission('owners', 'files', 'download');

    const enableDownloadFiles = data ? ((activeLayers?.indexOf('newprop')!==-1 && data['newprop']?.info)|| (activeLayers?.indexOf('ctrt') !== -1 && data['ctrt']?.info?.length > 0))&&!noPermissionsToDownload:false;

    /**In this case is necessary to use useMemo to avoid blinck on the info component */
    return useMemo(()=>{
        return(
            parentMap && !parentMap?.info && position && activeLayers && data.muni ?
                <Popup 
                    className="request-popup"
                    position={position} 
                    onClose={()=>setPosition(undefined)} 
                    closeOnEscapeKey={true}
                >
                    <div
                        style={{
                            maxHeight: 400,
                            overflow: "auto",
                            // minWidth: 360
                        }}
                    >
                        <div id="info-municipio">
                            <div>UF: <b>{data.muni?.muni1Nom}</b></div>
                            <div style={{color: 'gray', fontSize: 10}}>IBGE Code: {data.muni?.muni1Cod}</div>
                        </div>
                        {enableDownloadFiles&&
                            <div
                                style={{
                                    marginTop: 5,
                                    backgroundColor: theme.colors.blue,
                                    padding: '1px 2px',
                                    borderBottom: `2px solid gray`,
                                    color: theme.colors.primary
                                }}
                            >
                                <b>Files Download</b>
                            </div>
                        }
                        {enableDownloadFiles&&
                            <Grid container
                                sx={{
                                    width: 280
                                }}
                            >
                                {checkIfHasPermission('lands', 'files', 'download')&&
                                    <Grid item xs={4}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                        //value={files.indexOf('lands')!==-1} 
                                                        onChange={handleCheckbox}
                                                    // disabled={basemap}
                                                        // defaultChecked={files.indexOf('lands')!==-1}
                                                        // checked={files.indexOf('lands')!==-1}
                                                        id={"checkbox-file-lands"}
                                                        size={"small"}
                                                    />} 
                                            label={<Typography variant="body2" color="textSecondary">Lands</Typography>}
                                            sx={{fontSize: '5px', paddingTop: '2px', height: 30}}
                                        />   
                                    </Grid>  
                                }  
                                {checkIfHasPermission('contracts', 'files', 'download')&&
                                    <Grid item xs={4}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                        //value={files.indexOf('contracts')!==-1}
                                                        onChange={handleCheckbox}
                                                    // disabled={basemap}
                                                        //defaultChecked={files.indexOf('contracts')!==-1}
                                                        //checked={files.indexOf('contracts')!==-1}
                                                        id={"checkbox-file-contracts"}
                                                        size={"small"}
                                                    />} 
                                            label={<Typography variant="body2" color="textSecondary">Contract</Typography>}
                                            sx={{fontSize: '5px', paddingTop: '2px', height: 30}}
                                        />   
                                    </Grid>
                                } 
                                {checkIfHasPermission('owners', 'files', 'download')&&
                                    <Grid item xs={4}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                        //value={files.indexOf('owners')!==-1}
                                                        onChange={handleCheckbox}
                                                    // disabled={basemap}
                                                        //defaultChecked={files.indexOf('owners')!==-1}
                                                        //checked={files.indexOf('owners')!==-1}
                                                        id={"checkbox-file-owners"}
                                                        size={"small"}
                                                    />} 
                                            label={<Typography variant="body2" color="textSecondary">Owners</Typography>}
                                            sx={{fontSize: '5px', paddingTop: '2px', height: 30 }}
                                        />   
                                    </Grid>  
                                }                
                            </Grid>
                        }
                        {enableDownloadFiles&&
                            <div
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Button
                                    className="btn btn-primary"
                                    id="download-files"
                                    disabled={downloading}
                                    onClick={handleDownloadZip}
                                    sx={{
                                        background: `${theme.colors.secondary}!important`,
                                        color: theme.colors.primary
                                    }}
                                >
                                    {!downloading&&
                                        <>
                                            <span 
                                                id="text-download"
                                                style={{
                                                    padding: "0 10px",
                                                    fontSize: '12px'
                                                }}
                                            >   
                                                Zip Files 
                                                &nbsp; 
                                                <DownloadIcon
                                                    sx={{
                                                        fontSize: '12px'
                                                    }}
                                                />
                                            </span>
                                        </>}
                                    {downloading&&
                                        <span 
                                            id="text-generate"
                                            style={{
                                                padding: "0 10px",
                                                fontSize: '12px',
                                                color: `${theme.colors.primary}`
                                            }}
                                        >   
                                            Generating File &nbsp;
                                            <CircularProgress 
                                                id="download-progress"
                                                size={12}
                                                style={{color: theme.colors.primary}}
                                            />
                                        </span>
                                    }
                                </Button>
                            </div>
                        }
                        {enableDownloadFiles&&
                            <div
                                style={{
                                    fontSize: 12,
                                    color: 'red'
                                }}
                                id="message-download"
                            >
                                * If nothing is selected all the options is downloaded.
                            </div>
                        }
                        {enableDownloadFiles&&
                            <div
                                style={{
                                    fontSize: 12,
                                    color: 'red',
                                    display: 'none'
                                }}
                                id="message-download-failed"
                            >
                                * No files generated.
                            </div>
                        }
                        {activeLayers.map((codTxt)=> {
                            if(codTxt !== 'muni'){
                                return (
                                    <div key={`info-${codTxt}`} id={`info-${codTxt}`}>
                                        {transformInfoOnHtml(codTxt, data[codTxt], downloadKml, downloadShape)}
                                    </div>
                                )
                            }
                            return <></>
                        })}
                    </div>
                </Popup>
                :
                <></>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, downloading]); 

}