import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { RoutesApplication } from './routes';

import { LightTheme } from './styles/material-ui'; 

import { ThemeProvider as MUIThemeProvider } from '@mui/material'

import { ThemeProvider } from 'styled-components';
import { GlobalStyled } from './styles/global-styles';
import { theme } from './styles/theme';

import { 
  Box
} from "@mui/material";

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <MUIThemeProvider theme={LightTheme}>
        <ThemeProvider
          theme={theme}
        >
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden'
            }}
          >
            <RoutesApplication />
          </Box>
          <GlobalStyled />
        </ThemeProvider>
      </MUIThemeProvider>
    </React.StrictMode>
  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
