import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        /* background: ${theme.colors.primary}!important; */
        height:100vh;
        overflow: hidden;
        position: relative; 
        padding-bottom: 100px;

        .MuiToolbar-root {
            background: ${theme.colors.blue};
            display: flex;
            justify-content: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        form {
            margin: 20px 0; 
        }

        .MuiButtonBase-root[aria-label="ChevronLeftIcon"]{
            /* color: ${theme.colors.primary}; */
            left: 220px;
            position: absolute; 
        }

        #scroll {
            padding: 0; 
            height: calc(100vh - 67px - 220px);
            /* max-height: calc(100vh - 118px - 220px); */
            overflow-y: auto; 
            overflow-x: hidden;
        }

        #basemap-control {
            padding: 0 25px; 
        }

        div[aria-label="tab-controller"]{
            justify-content: center;
        }
    `}
`;

export const Logo = styled.div`
    max-width: 197px; 
`;

export const Footer = styled.div` 
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
`;
