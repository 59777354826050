import { COLOR_REG_FUND, MATR_STATUS } from "./leaflet-styles";

function scale(value:number, inMin:number, inMax:number, outMin:number, outMax:number) {
    const result = (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  
    if (result < outMin) {
      return outMin;
    } else if (result > outMax) {
      return outMax;
    }
  
    return result;
}
export class RegFundMethods {

    public convertDataToFeatures (data:any) {

        const featuresByBoundingBox = data.map((land:any)=>{ 

            const geometry = land.prop1_geom;
            const type = "Feature";

            const properties = {
                id: land.prop1_cod,
                name: land.prop1_nom,
                owner: land.prio, 
                contracts: land.prop1_ctrt_cache?JSON.parse(land.prop1_ctrt_cache):"", 
                comMat: land.commat,
                statusRf: land.strf,
                rank: land.rank,
                dominio: land.dominio, 
                geo: land.geo
            };
            
            const feature = {
                type: type,
                properties: properties,
                geometry: geometry
            }
            
            return feature;     
        });

        return featuresByBoundingBox; 

    }

    public createStyleByFeature(feature:any) {

        const statusRf:any = feature.properties.statusRf; 

        const comMat:boolean = feature.properties.comMat;

        return {
            fillColor: COLOR_REG_FUND[statusRf],
            fillOpacity: scale(feature.properties.rank, 0, 100, 0.7, 1),
            pane: 'regfund',
            ...MATR_STATUS[comMat?1:0]
        }

    }

}