import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, Paper, Stack, styled, SvgIcon, TextField, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import * as Styled from './styles';
import React, { useContext, useEffect, useState } from "react";
import {
    MapContainer,
    TileLayer
} from "react-leaflet";
import { AuthContext } from "../../routes/security";
import axios from "axios";
import { LandsMethods } from "../Map/utils/Lands/leaflet-lands-methods";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import TableSortLabel from '@mui/material/TableSortLabel'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { parseFileToGetDate, getUTMWtg, getUTMWtg2 } from "./util";
import Tooltip from '@mui/material/Tooltip';
import { parseFileToGetName } from "./util";
import { parseFileToGetType } from "./util";
import { parseTimeToGetDate } from "./util";
import { parseTimeToGetHour } from "./util";
import { getTypeOfFile } from "./util";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RuleIcon from '@mui/icons-material/Rule';
import GradingIcon from '@mui/icons-material/Grading';
import { WindTurbinesMethods } from "../Map/utils/WindTurbines/leaflet-wind-turbines-methods";
import moment from "moment";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ArticleIcon from '@mui/icons-material/Article';
import * as Yup from "yup";
import { AddComment, Comment, Download, Folder, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as WTGIcon } from './wtg-icon.svg';
import { theme } from "../../styles/theme";
import { Form, Formik } from "formik";

import EditIcon from '@mui/icons-material/Edit';

declare const L: any;

var mapsPlaceholder: any = [];

const StyledTab = styled((props: any) => (
    <Tab disableRipple {...props} />
))(({ theme }: any) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
    color: 'gray',
    minHeight: '45px',
    '&.Mui-selected': {
        color: 'gray',
    },
}));

export const Land = () => {

    /**
     * Get the config user!
    */
    const { user, sessionUser } = useContext(AuthContext);

    const firebaseUser = user;

    const { landId } = useParams();

    const [land, setLand] = useState<any>();
    const [landExists, setLandExists] = useState<boolean>(true);
    const [enterpriseOwner, setEnterprise] = useState<boolean>(false);

    // dialogs of files and chat
    const [ressalva, showRessalva] = useState<boolean>(false);
    const [dialogChat, showDialogChat] = useState<boolean>(false);
    const [themeChat, setThemeChat] = useState<any>('');
    const [titlesChat, setTitlesChat] = useState<Array<string>>(['']);


    // The current position of mouse
    let x = 0;

    // Width of left side
    const [leftWidth, setLeftWidth] = useState<number>(0);
    // let leftWidth = 0;

    const handleStartMap = (map: any) => {
        mapsPlaceholder.push(map);

        if (user?.accessToken)
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/${landId}/properties`, {
                headers: {
                    authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then(({ data }) => {
                    const {
                        prop1Cod,
                        prop1Geom,
                        prop1Nom,
                        prop1Ccir,
                        prop1PrioCache,
                        prop1CtrtCache,
                        prop1Matr,
                        prop1Nirf,
                        prop1CodCar,
                        prop1Dominio,
                        prop1GeoStatus,
                        statusGeo,
                        statusDominio,
                        municipio,
                        parq1Nom,
                        compl1Nom,
                        wtgGroup
                    } = data;

                    const landsMethods = new LandsMethods();

                    const feature = landsMethods.convertDataToFeatures([{
                        prop1Cod,
                        prop1Geom,
                        prop1Nom,
                        prop1Ccir,
                        prop1PrioCache,
                        prop1CtrtCache,
                        prop1Matr,
                        prop1Nirf,
                        prop1CodCar,
                        prop1Dominio,
                        prop1GeoStatus,
                        statusGeo,
                        statusDominio,
                        municipio,
                        parq1Nom,
                        compl1Nom
                    }]);

                    const geoJsonLands = L.geoJson(feature, {
                        style: (feature: any) => {
                            const styles = landsMethods.createStyleByFeature(feature);

                            delete styles["pane"];

                            return styles;
                        },
                        onEachFeature: function (feature: any, layer: any) {
                            const properties = feature.properties;

                            const htmlLegend = `
                            <div>
                                <div style="padding:1px;"><b style="color:red;">${properties.id}</b> - <b>${properties.name}</b></div>
                                <div><b>Owner:</b> ${properties.owner.split(";").join("</br>")}</div>

                                ${Object.entries(properties.contracts).length > 0 ?
                                    `
                                    <div>  
                                        <b>Contracts:</b>
                                        ${Object.entries(properties.contracts).map(([key, value]: any, index) => {
                                        const { txt } = value;

                                        if (index === 0) {
                                            return ` ${txt} - Reg.: ${key} `;
                                        } else {
                                            return `| ${txt} - Reg.: ${key} `;
                                        }
                                    }).join("<br/>")}
                                    </div>
                                `
                                    :
                                    ""}
                            </div>
                        `;

                            layer.bindTooltip(htmlLegend, { sticky: true, permanent: false })
                        }
                    });

                    const wtgMethods = new WindTurbinesMethods();

                    const featuresWtg = wtgMethods.convertDataToFeatures(wtgGroup.wtg ? wtgGroup.wtg : []);

                    map.createPane('aerg');

                    const geoJsonWtg = L.geoJson(featuresWtg, {
                        pointToLayer: (feature: any, latlng: any) => {
                            const styles = wtgMethods.iconTagByStatus(feature, latlng);

                            delete styles["pane"];

                            return styles;
                        },
                        onEachFeature: function (feature: any, layer: any) {
                            const properties = feature.properties;

                            const htmlLegend = `
                            <div>
                                <div><b>'ID: &nbsp'</b><b>${properties.id}</b></div>    
                                <div><b>${properties.maq}</b></div>
                                <div><b>Complex:</b> ${properties.complex}</div>
                                <div><b>Project:</b> ${properties.project}</div>
                                <div><b>FC:</b> ${properties.fc} %</div>
                            </div>
                        `;

                            layer.bindTooltip(htmlLegend, { sticky: true, permanent: false })
                        }
                    });


                    map.addLayer(geoJsonLands);
                    map.addLayer(geoJsonWtg);

                    const bounds = geoJsonLands.getBounds()

                    map.fitBounds(bounds);

                    /** Limit bounds and zoom of view lands */
                    map.setMaxBounds(bounds);
                    map.options.minZoom = map.getZoom();
                    map.options.maxZoom = map.getZoom();

                    L.control.scale().addTo(map);

                    setLand({
                        geometry: prop1Geom,
                        name: prop1Nom,
                        ccir: prop1Ccir,
                        owner: prop1PrioCache,
                        matr: prop1Matr,
                        nirf: prop1Nirf,
                        car: prop1CodCar,
                        geo: statusGeo,
                        domain: statusDominio,
                        city: municipio,
                        farm: parq1Nom,
                        complex: compl1Nom,
                        wtg: wtgGroup.wtg ? wtgGroup.wtg : [],
                        wtgTotalPowerKw: wtgGroup.totalPowerKw,
                        wtgTotalPowerKwByHa: wtgGroup.totalPowerKwByHa
                    });
                })
                .catch((e) => {
                    console.log(e);
                    setLandExists(false);
                })
    }

    /**Checking permissions to download files and show checkbox**/
    const permissions = sessionUser?.permissions ? sessionUser?.permissions : [];

    const checkIfHasPermission = (target: string, resource: string, verb: string) => {
        return permissions?.findIndex((permission: any) => {
            return permission === `${target}.${resource}.${verb}`;
        }) !== -1;
    }

    const downloadFile = (target: 'owners' | 'contracts' | 'lands', targetId: string, fileId: string) => {

        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/files/${target}/${targetId}/${fileId}`, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
            .then(({ data }) => {

                const {
                    signed_url
                } = data;

                window.open(signed_url, "_blank")

            })
            .catch((e) =>
                alert(`file not found`)
                // showErrorMessage({
                //     text: `File not found!`,
                //     severity: 'warning'
                // })
            )

    }

    const mouseDownHandler = function (e: any) {
        e.preventDefault();
        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e: any) {

        const resizer: any = document.getElementById('dragMe');

        const leftSide = resizer.previousElementSibling;

        // How far the mouse has been moved
        const dx = e.clientX - x;

        const newLeftWidth = ((leftWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;

        setLeftWidth(newLeftWidth);

        if (newLeftWidth > 30 && newLeftWidth < 60) {
            const [map] = mapsPlaceholder;
            map?.invalidateSize();
            leftSide.style.width = `${newLeftWidth}%`;
        }

    };


    const mouseUpHandler = function (e: any) {

        const resizer: any = document.getElementById('dragMe');

        const leftSide = resizer.previousElementSibling;
        const rightSide = resizer.nextElementSibling;

        resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        leftSide.style.removeProperty('user-select');
        leftSide.style.removeProperty('pointer-events');

        rightSide.style.removeProperty('user-select');
        rightSide.style.removeProperty('pointer-events');

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

    };

    const [geoData, setGeoData] = useState<any>();
    const [ownersData, setOwnersData] = useState<Array<any>>();
    const [filesData, setFilesData] = useState<Array<any>>();
    const [chatData, setChatData] = useState<Array<any>>();

    const [value, setValue] = React.useState('general');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {

        const [map] = mapsPlaceholder;

        if (!geoData && value === "general")
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/${landId}/properties/geo`, {
                headers: {
                    authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then(({ data }) => {
                    setGeoData(data)
                    map?.invalidateSize();
                })
                .catch((e) => {
                    alert(`Error trying to get info of geo`)
                })

        if (!ownersData && value === "owners")
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/${landId}/properties/owners`, {
                headers: {
                    authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then(({ data }) => {
                    setOwnersData(data)
                    map?.invalidateSize();
                })
                .catch((e) => {
                    alert(`Error trying to get info of geo`)
                    setOwnersData([])
                })

        if (!filesData && value === "files")
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/files/lands/${landId}`, {
                headers: {
                    authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then(({ data }) => {
                    setFilesData(data)
                    map?.invalidateSize();

                })
                .catch((e) => {
                    alert(`Error trying to get info of geo`)
                    setFilesData([])
                })

        if (!chatData && value === "chat")
            axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/api/landsComments/${landId}`, {
                headers: {
                    authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then(({ data }) => {
                    setChatData(data)
                    map?.invalidateSize();

                })
                .catch((e) => {
                    alert(`Error trying to get info of chat`)
                    setChatData([])
                })

        map?.invalidateSize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, chatData])


    const [comment, setComment] = useState({
        title: "",
        text: ""
    });

    const [editComment, setEditComment] = useState("");
    const [openNewComment, setOpenNewComment] = useState(false);

    const handleNewComment = (title?: string, text?: string, idComment?: string) => {

        if (idComment) {
            const defaultValue = {
                title: title ? title : "",
                text: text ? text : ""
            }

            setEditComment(idComment);

            setComment(defaultValue);
        }

        setOpenNewComment(true);
    };

    const handleCloseNewComment = () => {
        setComment({
            title: "",
            text: ""
        });
        setOpenNewComment(false);

        if (editComment)
            setEditComment("");
    };

    const fetchSaveLandsComment = (title: string, text: string) => {

        axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/landsComments/${landId}`, {
            title,
            text,
            active: true
        },
            {
                headers: {
                    'Authorization': `Bearer ${user?.accessToken}`,
                }
            })
            .then(({ data }) => {
                /**Possibly in the future, only add this item to chatData */
                setOpenNewComment(false);
                setChatData(undefined);
            })
            .catch((e) => {
                console.log(e);
                alert(`Error trying to save a new comment.`);
            })

    }

    const editLandsComment = (title: string, text: string, editComment: string) => {

        axios.put(`${process.env.REACT_APP_AUTH_SERVICE}/api/landsComments/${editComment}`, {
            title,
            text,
            active: true
        },
            {
                headers: {
                    'Authorization': `Bearer ${user?.accessToken}`,
                }
            })
            .then(({ data }) => {
                /**Possibly in the future, only add this item to chatData */
                setComment({
                    title: "",
                    text: ""
                });
                setOpenNewComment(false);
                setEditComment("");
                setChatData(undefined);
            })
            .catch((e) => {
                console.log(e);
                alert(`Error trying to save a new comment.`);
            })

    }

    const toggleCommentVisibility = (title: string, text: string, id: string, is_active: string) => {

        axios.put(`${process.env.REACT_APP_AUTH_SERVICE}/api/landsComments/${id}`, {
            title,
            text,
            active: !is_active
        },
            {
                headers: {
                    'Authorization': `Bearer ${user?.accessToken}`,
                }
            })
            .then(({ data }) => {
                /**Possibly in the future, only add this item to chatData */
                setComment({
                    title: "",
                    text: ""
                });
                setOpenNewComment(false);
                setEditComment("");
                setChatData(undefined);
            })
            .catch((e) => {
                console.log(e);
                alert(`Error trying to save a new comment.`);
            })

    }

    const downloadKml =
        (landId: string) => {

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry/kml/${landId}`, {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    const { url } = data;

                    window.open(url, '_blank');
                })
                .catch((e) => {
                    alert(e)
                });
        };

    const downloadShape =
        (landId: string) => {

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry/shapefile/${landId}`, {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    const { signed_url } = data;

                    window.open(signed_url, '_blank');
                })
                .catch((e) => {
                    alert(e)
                });
        };

    if (!landId)
        return <div>No lands specified...</div>

    return (
        <Styled.Resize>
            <Toolbar />
            <Paper elevation={2} id="form-admin">
                {landExists ?
                    <div className="container">
                        <div className="container__left" >
                            {land ?
                                <div style={{ width: '100%' }}>
                                    <div>
                                        Land <b style={{ color: '#3EA036' }}>{landId}</b>
                                        {checkIfHasPermission('lands', 'kmz', 'download') && checkIfHasPermission('lands', 'kmz', 'download') &&
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    fontSize: 10
                                                }}
                                                onClick={(e) => { downloadKml(landId) }}
                                            >
                                                <Download
                                                    sx={{
                                                        fontSize: 15
                                                    }}
                                                />
                                                KML
                                            </IconButton>
                                        }
                                        {checkIfHasPermission('lands', 'kmz', 'download') &&
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    fontSize: 10
                                                }}
                                                onClick={(e) => downloadShape(landId)}
                                            >
                                                <Download
                                                    sx={{
                                                        fontSize: 15
                                                    }}
                                                />
                                                Shape
                                            </IconButton>
                                        }
                                    </div>
                                    <div><b style={{ color: '#088BAE', font: '160% sans-serif' }}>{land.name}</b></div>
                                    {land.matr && <div style={{ font: '70% sans-serif' }}>Matr.: <b style={{ color: '#088BAE' }}>{land.matr}</b></div>}
                                    {land.ccir && <div style={{ font: '70% sans-serif' }}>CCIR: <b style={{ color: '#088BAE' }}>{land.ccir}</b></div>}
                                    {land.nirf && <div style={{ font: '70% sans-serif' }}>Nirf.: <b style={{ color: '#088BAE' }}>{land.nirf}</b></div>}
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', paddingTop: '5px' }}>
                                            <TabList
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons
                                                // visibleScrollbar
                                                allowScrollButtonsMobile
                                                sx={{
                                                    '& .MuiTabs-indicator': {
                                                        backgroundColor: '#088BAE',
                                                        height: '3px'
                                                    }
                                                }}
                                            >
                                                <StyledTab
                                                    label="General"
                                                    icon={
                                                        <ContentPasteIcon sx={{ fontSize: '16px' }} />
                                                    }
                                                    iconPosition="start"
                                                    value="general"
                                                // sx={{ width: '10px' }}

                                                />
                                                <StyledTab
                                                    label="Chat"
                                                    icon={<Comment sx={{ fontSize: '16px' }} />}
                                                    iconPosition="start"
                                                    value="chat"

                                                />
                                                <StyledTab
                                                    label="Contracts"
                                                    icon={<ArticleIcon sx={{ fontSize: '16px' }} />}
                                                    iconPosition="start"
                                                    value="contracts"

                                                />
                                                <StyledTab
                                                    label="Owners"
                                                    icon={<Person sx={{ fontSize: '16px' }} />}
                                                    iconPosition="start"
                                                    value="owners"

                                                />
                                                <StyledTab
                                                    label="Files"
                                                    icon={<Folder sx={{ fontSize: '16px' }} />}
                                                    iconPosition="start"
                                                    value="files"

                                                />
                                                <StyledTab
                                                    label="WTG"
                                                    icon={
                                                        <SvgIcon
                                                            sx={{ fontSize: '16px' }}
                                                            component={WTGIcon}
                                                        />
                                                    }
                                                    iconPosition="start"
                                                    value="wtg"

                                                />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="general" >
                                            <Paper style={{ padding: "10px 5px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        Status GEO:
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <b>{land.geo}</b>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        Domain:
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <b>{land.domain}</b>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        Farm:
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <b>{land.farm}</b>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        Complex:
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <b>{land.complex}</b>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                            <Paper>
                                                <div style={{ padding: "10px 5px", margin: "10px", height: "10px" }}>
                                                    <b>Geodata</b>
                                                </div>
                                                <div>
                                                    {geoData ?
                                                        <>
                                                            <Paper style={{ padding: "10px 5px" }}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        City:
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>
                                                                            {geoData.cities.map(({ area, city }: any, index: number) => {
                                                                                if (index === 0)
                                                                                    return `${city} : ${Math.round(area * 100)}%`
                                                                                else
                                                                                    return ` | ${city} : ${Math.round(area * 100)}% `
                                                                            })}
                                                                        </b>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        Perimeter:
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>{geoData.perimeter.toFixed(2)} m</b>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        Geometry Area:
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>{geoData.areaHa.toFixed(4)} ha</b>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        Doc. Area:
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>{geoData.areaDoc.toFixed(4)} ha</b>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        Consid. Area:
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>{geoData.areaConsid.toFixed(4)} ha</b>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <div style={{ fontSize: '16px' }}>City:&nbsp;
                                                                    <b>
                                                                        {geoData.cities.map(({ area, city }: any, index: number) => {
                                                                            if (index === 0)
                                                                                return `${city} : ${Math.round(area * 100)}%`
                                                                            else
                                                                                return ` | ${city} : ${Math.round(area * 100)}% `
                                                                        })}
                                                                    </b>
                                                                </div>
                                                                <div>Perimeter: <b>{geoData.perimeter.toFixed(2)} m</b></div>
                                                                <div>Geometry Area: <b>{geoData.areaHa.toFixed(4)} ha</b></div>
                                                                {geoData.areaDoc && <div>Doc. Area: <b>{geoData.areaDoc.toFixed(4)} ha</b></div>}
                                                                {geoData.areaConsid && <div>Consid. Area: <b>{geoData.areaConsid.toFixed(4)} ha</b></div>} */}
                                                            </Paper>
                                                            {(geoData.car.code || geoData.sigef.code || geoData.snci.code) &&
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            padding: '5px'
                                                                        }}
                                                                    />
                                                                    <Paper style={{ padding: "10px 5px", overflowWrap: 'break-word', wordBreak: 'break-all' }}>
                                                                        <div id="tab-admin-title">Overlays</div>
                                                                        {geoData.car.code && <div><b>CAR: </b>{geoData.car.code}</div>}
                                                                        {geoData.sigef.code && <div><b>SIGEF: </b>{geoData.sigef.code}</div>}
                                                                        {geoData.snci.code && <div><b>SNCI: </b>{geoData.snci.code}</div>}
                                                                    </Paper>
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <LinearProgress />
                                                    }
                                                </div>
                                            </Paper>

                                        </TabPanel>
                                        <TabPanel value="chat">
                                            <div>
                                                <Stack direction="row" spacing={2}>
                                                    <Button
                                                        sx={{ color: theme.colors.dark }}
                                                        startIcon={<AddComment />}
                                                        onClick={() => handleNewComment()}
                                                    >
                                                        Add
                                                    </Button>
                                                </Stack>
                                                <h3> 
                                                    <b color="#088BAE">
                                                        Subjects
                                                    </b>
                                                </h3>
                                                {chatData ?
                                                    chatData.length > 0 ?
                                                        <>
                                                            {chatData.map((comment) => {
                                                                const {
                                                                    id,
                                                                    user,
                                                                    text,
                                                                    title,
                                                                    edited_at,
                                                                    is_active
                                                                } = comment;
                                                                const {
                                                                    name,
                                                                    picture,
                                                                    email
                                                                } = user;
                                                                const showEdition = firebaseUser.email === email;
                                                                const isAdmin = sessionUser.is_admin;

                                                                return (
                                                                    <>
                                                                        <Paper key={`comment-${id}`} style={{ padding: "5px 5px" }}>
                                                                            <List>
                                                                            { (titlesChat.includes(title)) ? <></> : 
                                                                                <ListItemButton onClick={() => { showDialogChat(true); setThemeChat({ title }) }}>
                                                                                    {title}
                                                                                </ListItemButton>
                                                                            }
                                                                            </List>
                                                                        </Paper>
                                                                        <div
                                                                            style={{
                                                                                padding: '5px'
                                                                            }}
                                                                        />
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <div>No comments to show.</div>
                                                    :
                                                    <LinearProgress />
                                                }
                                                <Dialog open={dialogChat} onClose={() => showDialogChat(false)}>
                                                    {chatData ?
                                                         chatData.length > 0 ?
                                                        <>
                                                            {chatData.map((comment) => {
                                                                const {
                                                                    id,
                                                                    user,
                                                                    text,
                                                                    title,
                                                                    edited_at,
                                                                    is_active
                                                                } = comment;

                                                                const {
                                                                    name,
                                                                    picture,
                                                                    email
                                                                } = user;

                                                                const showEdition = firebaseUser.email === email;
                                                                const isAdmin = sessionUser.is_admin;
                                                                // console.log(title);
                                                                console.log(themeChat);
                                                                // console.log(comment);
                                                                if(true) {
                                                                return (
                                                                    <>
                                                                        {title === themeChat.title ? 
                                                                        <Paper sx={{ padding: '3', margin: '15px', alignContent:'center', alignItems:'center'}}>
                                                                            <Grid container wrap="nowrap" spacing={2}>
                                                                                <Grid item>
                                                                                    <Avatar alt={name} src={picture} />
                                                                                </Grid>
                                                                                <Grid justifyContent="left" item xs zeroMinWidth>
                                                                                    <h4 style={{ margin: 1, padding: 1, textAlign: "left", color:"#088BAE" }}>
                                                                                        {name}
                                                                                        <p style={{ textAlign: "left", color: "gray", font: "70% sans-serif" }}>
                                                                                        posted in {moment(edited_at).format('MMMM Do YYYY')}
                                                                                        </p>
                                                                                        {isAdmin &&
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() => toggleCommentVisibility(title, text, id, is_active)}
                                                                                            >
                                                                                                {is_active ?
                                                                                                    <Visibility
                                                                                                        fontSize="inherit"
                                                                                                    />
                                                                                                    :
                                                                                                    <VisibilityOff
                                                                                                        fontSize="inherit"
                                                                                                    />
                                                                                                }
                                                                                            </IconButton>
                                                                                        }
                                                                                    </h4>
                                                                                    <p style={{ textAlign: "left" }}>
                                                                                        {text}
                                                                                        {showEdition &&
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                color='primary'
                                                                                                onClick={() => handleNewComment(title, text, id)}
                                                                                            >
                                                                                                <EditIcon
                                                                                                    fontSize="inherit"
                                                                                                    color="primary"
                                                                                                />
                                                                                            </IconButton>
                                                                                        }
                                                                                    </p>
                                                                                    
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Paper>
                                                                        : <></>}
                                                                    </>
                                                                )}
                                                                return <LinearProgress/>;
                                                            })}
                                                        </> : <div>No comments to show</div>
                                                        :
                                                        <LinearProgress />
                                                    }
                                                </Dialog>

                                                <Dialog
                                                    open={openNewComment}
                                                    onClose={handleCloseNewComment}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {editComment ? `Edit comment ${editComment}` : "New comment"}
                                                    </DialogTitle>
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={comment}
                                                        validateOnChange={false}
                                                        onSubmit={(values, actions) => {
                                                            const { title, text } = values;

                                                            if (
                                                                title && text
                                                            ) {
                                                                if (!editComment)
                                                                    fetchSaveLandsComment(title, text);
                                                                else
                                                                    editLandsComment(title, text, editComment);
                                                            }
                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            title: Yup.string().required("Required field."),
                                                            text: Yup.string().required("Required field.")
                                                        })}
                                                    >
                                                        {({
                                                            values,
                                                            errors,
                                                            touched,
                                                            handleChange,
                                                            handleBlur
                                                        }) => (
                                                            <Form
                                                                noValidate
                                                            >
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">
                                                                        <TextField
                                                                            autoFocus
                                                                            required
                                                                            autoComplete='off'
                                                                            id="title"
                                                                            name="title"
                                                                            label="Subject"
                                                                            variant="outlined"
                                                                            className="txt-field"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.title}
                                                                            helperText={
                                                                                errors.title && touched.title ? errors.title : ""
                                                                            }
                                                                            error={errors.title && touched.title ? true : false}
                                                                            sx={{
                                                                                width: '100%'
                                                                            }}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                padding: '20px'
                                                                            }}
                                                                        />
                                                                        <TextField
                                                                            autoFocus
                                                                            required
                                                                            autoComplete='off'
                                                                            id="text"
                                                                            name="text"
                                                                            label="Text"
                                                                            variant="outlined"
                                                                            className="txt-field"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.text}
                                                                            multiline
                                                                            helperText={
                                                                                errors.text && touched.text ? errors.text : ""
                                                                            }
                                                                            error={errors.text && touched.text ? true : false}
                                                                            sx={{
                                                                                width: '100%'
                                                                            }}
                                                                        />
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleCloseNewComment}>Cancel</Button>
                                                                    <Button type='submit' autoFocus>
                                                                        {editComment ? "Edit" : "Save"}
                                                                    </Button>
                                                                </DialogActions>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Dialog>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="contracts">
                                            <div>
                                                Info dos Contratos
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="owners">
                                            {ownersData ?
                                                ownersData.length > 0 ?
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: undefined }} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><b>ID</b></TableCell>
                                                                    <TableCell align="left" ><b>Name</b></TableCell>
                                                                    <TableCell align="left" > {enterpriseOwner ?
                                                                        <b> CNPJ </b>
                                                                        : <b> CPF </b>}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {ownersData.map((row) => (
                                                                    <TableRow
                                                                        key={row.prio1Cod}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {row.prio1Cod}
                                                                        </TableCell>
                                                                        <TableCell align="left">{row.prio1Nom}</TableCell>
                                                                        <TableCell align="left">{row.prio1Doc}</TableCell>
                                                                        {() => { row.prio1Doc.lenght() > 12 ? setEnterprise(true) : setEnterprise(false) }}
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <div>No owners associated with this land.</div>
                                                :
                                                <LinearProgress />
                                            }
                                        </TabPanel>
                                        <TabPanel value="files">
                                            {filesData ?
                                                filesData.length > 0 ?
                                                    <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
                                                        <Table sx={{ minWidth: undefined }} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">
                                                                        <b>Type</b>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        <b>Name</b>
                                                                    </TableCell>
                                                                    <TableCell align="left"><b>Status</b></TableCell>
                                                                    <TableCell align="left"><b>Date of File</b></TableCell>
                                                                    <TableCell align="left"><b>Uploaded</b></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {filesData.map((row) => (
                                                                    <TableRow
                                                                        key={row.arqu1Nom}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row" align="left">
                                                                            <Tooltip title={getTypeOfFile(row.arqu1Nom)}>
                                                                                <div className="myDIV">
                                                                                    {parseFileToGetType(row.arqu1Nom)}
                                                                                </div>
                                                                            </Tooltip>


                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row" align="left">
                                                                            <Tooltip title={'Click to Download'}>
                                                                                <div onClick={() => downloadFile('lands', landId, row.arqu1Id)} style={{ color: '#088BAE', cursor: 'pointer', textOverflow: 'ellipsis' }}>
                                                                                    <b>{parseFileToGetName(row.arqu1Nom)}</b>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {row.arqu1StVerif === 'v' ?
                                                                                <>
                                                                                    <Tooltip title={'Verificado'}>
                                                                                        <CheckCircleOutlineIcon color="success" />
                                                                                    </Tooltip>
                                                                                </>

                                                                                : (row.arqu1StVerif === 'rs') ?
                                                                                    <>
                                                                                        <Tooltip title={'Ressalva, clique para mais informações.'}>
                                                                                            <RuleIcon color="warning" onClick={() => { showRessalva(true) }} />
                                                                                        </Tooltip>
                                                                                        <Dialog
                                                                                            open={ressalva}
                                                                                            onClose={() => showRessalva(false)}
                                                                                            aria-labelledby="alert-dialog-ressalva"
                                                                                            aria-describedby="ressalva do arquivo"
                                                                                        >

                                                                                            <DialogTitle id="alert-dialog-title">
                                                                                                {"Consta no documento a seguinte ressalva:"}
                                                                                            </DialogTitle>
                                                                                            <DialogContent>
                                                                                                <DialogContentText id="alert-dialog-description">
                                                                                                    {row.arqu1MotRecu}
                                                                                                </DialogContentText>
                                                                                            </DialogContent>
                                                                                            <DialogActions>
                                                                                                <Button onClick={() => showRessalva(false)}>Cancelar</Button>
                                                                                                <Button onClick={() => showRessalva(false)} autoFocus>
                                                                                                    Aceitar Ressalva
                                                                                                </Button>
                                                                                            </DialogActions>
                                                                                        </Dialog>
                                                                                    </>

                                                                                    :
                                                                                    <>
                                                                                        <Tooltip title={'Aprovado com Ressalva, clique para mais informações.'}>
                                                                                            <GradingIcon color="info" onClick={() => { showRessalva(true) }} />
                                                                                        </Tooltip>
                                                                                        <Dialog
                                                                                            open={ressalva}
                                                                                            onClose={() => showRessalva(false)}
                                                                                            aria-labelledby="alert-dialog-ressalva"
                                                                                            aria-describedby="ressalva do arquivo"
                                                                                        >
                                                                                            <DialogTitle id="alert-dialog-title">
                                                                                                {"O documento foi aceito com a seguinte ressalva:"}
                                                                                            </DialogTitle>
                                                                                            <DialogContent>
                                                                                                <DialogContentText id="alert-dialog-description">
                                                                                                    {row.arqu1MotRecu}
                                                                                                </DialogContentText>
                                                                                            </DialogContent>
                                                                                            <DialogActions>
                                                                                                <Button onClick={() => showRessalva(false)}>Cancelar</Button>
                                                                                                <Button onClick={() => showRessalva(false)} autoFocus>
                                                                                                    Ok
                                                                                                </Button>
                                                                                            </DialogActions>
                                                                                        </Dialog>
                                                                                    </>

                                                                            }


                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <b>{parseFileToGetDate(row.arqu1Nom)}</b>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <b>{parseTimeToGetDate(row.arqu1DatUpload)}</b>
                                                                            <p>{parseTimeToGetHour(row.arqu1DatUpload)}</p>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <div>No files associated with this land.</div>
                                                :
                                                <LinearProgress />}
                                        </TabPanel>
                                        <TabPanel value="wtg">
                                            {land.wtg ?
                                                land.wtg.length > 0 ?
                                                    <>
                                                        <div>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: '#088BAE',
                                                                    '&:hover': {
                                                                        backgroundColor: '#088BAE',
                                                                        opacity: [0.9, 0.8, 0.7],
                                                                    },
                                                                    margin: '10px 0',
                                                                    color: '#FAFAFA',
                                                                    padding: '5px',
                                                                    borderRadius: '5px'
                                                                }}
                                                            >
                                                                <b>Power Status</b>
                                                            </Box>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: '#3EA036',
                                                                            '&:hover': {
                                                                                backgroundColor: '#3EA036',
                                                                                opacity: [0.9, 0.8, 0.7],
                                                                            },
                                                                            padding: '10px',
                                                                            color: '#FAFAFA',
                                                                            borderRadius: '5px'
                                                                        }}
                                                                    >
                                                                        {(land.wtgTotalPowerKw) / 1000} <br /> MW
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: '#3EA036',
                                                                            '&:hover': {
                                                                                backgroundColor: '#3EA036',
                                                                                opacity: [0.9, 0.8, 0.7],
                                                                            },
                                                                            padding: '10px',
                                                                            color: '#FAFAFA',
                                                                            borderRadius: '5px'
                                                                        }}
                                                                    >
                                                                        {((land.wtg.reduce((a: any, v: any) => a = a + v.aerg1Fc, 0)) / (land.wtg).length).toFixed(2)} %<br /> Medium FC
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: '#3EA036',
                                                                            '&:hover': {
                                                                                backgroundColor: '#3EA036',
                                                                                opacity: [0.9, 0.8, 0.7],
                                                                            },
                                                                            padding: '10px',
                                                                            color: '#FAFAFA',
                                                                            borderRadius: '5px',

                                                                        }}
                                                                    >
                                                                        {(land.wtg).length}  <br /> Turbines
                                                                        {/* <p style={{fontSize: '74%', marginTop: '4px'}}>{(land.wtg[0].aerg1Maq)} </p> */}

                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingTop: '15px'
                                                            }}
                                                        >
                                                            <TableContainer component={Paper} sx={{ maxHeight: '40vh' }}>
                                                                <Table sx={{ minWidth: undefined }} size="small" aria-label="a dense table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="left"><b>ID</b></TableCell>
                                                                            <TableCell align="left"><b>E(m)</b></TableCell>
                                                                            <TableCell align="left"><b>N(m)</b></TableCell>
                                                                            <TableCell align="left"><b>Zone</b></TableCell>
                                                                            <TableCell align="left"><b>Power (MW)</b></TableCell>
                                                                            <TableCell align="left"><b>FC(%)</b></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {land.wtg.map((row: any) => (
                                                                            <TableRow
                                                                                key={`${row.aerg1Cod}`}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row" align="left">
                                                                                    {row.aerg1Cod}
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row" align="left">
                                                                                    {getUTMWtg(row.aerg1Geom.coordinates[0], row.aerg1Geom.coordinates[1], 1)}
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row" align="left">
                                                                                    {getUTMWtg2(row.aerg1Geom.coordinates[0], row.aerg1Geom.coordinates[1], 1)}
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row" align="left">
                                                                                    {getUTMWtg(row.aerg1Geom.coordinates[0], row.aerg1Geom.coordinates[1], 2)}
                                                                                </TableCell>
                                                                                <TableCell align="left">{(row.aerg1Pot) / 1000}</TableCell>
                                                                                <TableCell align="left">{row.aerg1Fc.toFixed(2)}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>
                                                        <div>
                                                            <Box sx={{ marginTop: '30px', borderRadius: '5px' }}>
                                                                <Paper sx={{ padding: '6px' }}><b>Turbine Model: </b>{(land.wtg[0].aerg1Maq)}</Paper>
                                                                {/* Turbine Model {(land.wtg[0].aerg1Maq)} */}
                                                            </Box>
                                                        </div>
                                                    </>
                                                    :
                                                    <div>No wind turbines associated with this land.</div>
                                                :
                                                <LinearProgress />}
                                        </TabPanel>
                                    </TabContext>
                                </div>
                                :
                                <LinearProgress />
                            }
                        </div>
                        <div
                            className="resizer" id="dragMe"
                            onMouseDown={(e: any) => mouseDownHandler(e)}
                        ></div>
                        <MapContainer
                            zoomControl={false}
                            zoom={13}
                            scrollWheelZoom={true}
                            id={`map-lands-${landId}`}
                            className="container__right"
                            whenCreated={handleStartMap}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>
                    :
                    <div>Land doesn't exists</div>
                }
            </Paper>
        </Styled.Resize>
    )
}