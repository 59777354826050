import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

// Your web app's Firebase configuration
export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

if (!firebase.apps.length) {
  const app = firebase.initializeApp(config);
  const analytics = firebase.analytics(app);
  
  /**Use analytics to dispatch events in Google Analytics */
}

export const auth = firebase.auth();
