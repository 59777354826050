import { 
    MapContainer, 
    Rectangle,
    Pane
} from "react-leaflet";

import * as Styled from './styles';

import { LatLngBounds } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';

import { 
    useCallback, 
    useContext, 
    useEffect, 
    useState 
} from "react";

import { Footer } from "../../mapComponents/Footer";

import { Toolbar } from "../../mapComponents/Toolbar";

import 'leaflet-minimap/src/Control.MiniMap';
import 'leaflet-minimap/src/Control.MiniMap.css';

import 'leaflet-betterscale/L.Control.BetterScale';
import 'leaflet-betterscale/L.Control.BetterScale.css';

import 'leaflet-contextmenu/dist/leaflet.contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';

import { LeafletLegend } from "./utils/leaflet-legend";
import { LayersControllerClass } from "./utils/layers-control";

import { LeafletUserConfig } from './utils/leaflet-user-config';

import axios from 'axios';
import { AuthContext } from "../../routes/security";
import { Info } from "../../mapComponents/Info";

import ZoomIn from './icons/zoom-in.png';
import ZoomOut from './icons/zoom-out.png';

import { saveAs } from 'file-saver';
import moment from "moment";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import $ from "jquery";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Filter } from "../../mapComponents/Filter";
import { DialogFiles } from "../../components/DialogFiles";

declare const L: any;

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const minimapOptions = {
    toggleDisplay: true, 
    minimized: true,
    minZoom: 0,
    maxZoom: 13,
    strings: {
        hideText: "Hide Minimap",
        showText: "Show Minimap"
    }
}

interface MapProps {
    open: boolean; 
    setGlobalMap: Function; 
}

interface RectangleZoomProps {
    boundsRectangle?:LatLngBounds
}

export const MapComponent  = ({
    open,
    setGlobalMap
}:MapProps) => {

    /**
     * Get the config user!
    */
    const { user, sessionUser } = useContext(AuthContext);

    const [map, setMap] = useState<any>();
    const [rectangle, setBoundsRectangle] = useState<LatLngBounds|undefined>();
    const [initialPoint, setInitialPoint] = useState<LatLngBounds|undefined>();
    const [minimapAdded, setMinimapAdded] = useState<boolean>(false);

    const centerMap = (e:any) => {

        const { map } = e; 

        map.panTo(e.latlng);

    }

    const downloadKmz = async (e:any) => {

        const { map } = e; 

        const bounds = map.getBounds();

        const request = {
            minlat: bounds?.getSouthWest().lat.toPrecision(5),
            minlng: bounds?.getSouthWest().lng.toPrecision(5),
            maxlat: bounds?.getNorthEast().lat.toPrecision(5),
            maxlng: bounds?.getNorthEast().lng.toPrecision(5),
            filter: map.filter
        };
        
        await axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/geometry/kml`,
        request,
        {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then((res:any)=> {
            const { url } = res.data; 

            window.open(url, "_blank")

        })
        .catch((e)=>{
            alert("Error generating KMZ.")
        })

    }

    const downloadFiles = async (e:any) => {

        const { map } = e; 

        const bounds = map.getBounds();

        const request = {
            minlat: bounds?.getSouthWest().lat.toPrecision(5),
            minlng: bounds?.getSouthWest().lng.toPrecision(5),
            maxlat: bounds?.getNorthEast().lat.toPrecision(5),
            maxlng: bounds?.getNorthEast().lng.toPrecision(5),
            filter: map.filter
        };

        if($('#leaflet-tile-loading').length > 0){
            $('#leaflet-tile-loading').show();
        }
        
        await axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/files/boundingbox`,
        request,
        {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then((res:any)=> {

            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').hide();
            }
            
            const { url } = res.data; 

            window.open(url, "_blank")

        })
        .catch((e)=>{
            if($('#leaflet-tile-loading').length > 0){
                $('#leaflet-tile-loading').hide();
            }
            if($('#leaflet-tile-error').length > 0){
                $('#leaflet-tile-error').show();
            }
        })

    }

    const downloadSheets = async (e:any) => {

        const { map } = e; 

        const bounds = map.getBounds();

        const request = {
            minlat: bounds?.getSouthWest().lat.toPrecision(5),
            minlng: bounds?.getSouthWest().lng.toPrecision(5),
            maxlat: bounds?.getNorthEast().lat.toPrecision(5),
            maxlng: bounds?.getNorthEast().lng.toPrecision(5),
            filter: map.filter
        };

        await axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/files/sheets/boundingbox`,
        request,
        {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            },
            responseType: 'blob'
        })
        .then((res:any)=> {

            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })

            saveAs(blob, `sheets-boundingbox-${moment(Date.now()).format('YYYY-MMMM-DD_hh-mm-ss')}.xlsx`)

        })
        .catch((e)=>{
            alert("Error generating Files.")
        })
        
    }

    /**Download of shapefiles */
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const [value, setValue] = useState('lands');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleClose = () => {

        const bounds = map.getBounds();

        const body = {
            minlat: bounds?.getSouthWest().lat.toPrecision(5),
            minlng: bounds?.getSouthWest().lng.toPrecision(5),
            maxlat: bounds?.getNorthEast().lat.toPrecision(5),
            maxlng: bounds?.getNorthEast().lng.toPrecision(5),
            filter: map.filter
        };

        axios.post(`${process.env.REACT_APP_WEB_SERVICE}/api/${value === 'regfund'? 'lands' : value}/geometry/shapefile${value === 'regfund'? '/regfund' : ''}`, 
        body
        ,{
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then((res)=>{
            return res.data;
        })
        .then((data)=>{
            const { signed_url } = data; 

            window.open(signed_url, '_blank'); 

            setOpenDialog(false);
        })
        .catch((e)=>{
            alert(e)
        });
        
    };
    
    const contextMenu = {
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [{
            text: 'Center map here',
            callback: centerMap
        }, 
        '-', 
        {
            text: 'Zoom in',
            icon: ZoomIn,
            callback: zoomIn
        }, 
        {
            text: 'Zoom out',
            icon: ZoomOut,
            callback: zoomOut
        },
        {
            text: 'Download KMZ',
            callback: downloadKmz
        },
        {
            text: 'Download Shape',
            callback: handleClickOpen
        },
        {
            text: 'Download Files',
            callback: downloadFiles
        },
        {
            text: 'Export Sheets',
            callback: downloadSheets
        }]
    };
        
    const handleStartMap = (map:any) => {
        setMap(map);
    }

    function zoomIn (e:any) {
        const { map } = e; 
        map?.zoomIn();
    }
     
    function zoomOut (e:any) {
        const { map } = e; 

        map?.zoomOut();
    }

    useEffect(()=>{
        if(map?._state!==undefined){
            setGlobalMap(map);
        }
    }, [map, minimapAdded, setGlobalMap])

    /** Component will unmount, clear the variable map! */
    useEffect(() => {
        return () => {
            setMap(undefined);
            setGlobalMap(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [start, setStart]=useState<boolean>(false);

    useEffect(()=>{
        if(!open){
            map?.invalidateSize();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    /** Use the class of user config to set preferences of the user on the main screen */
    const [leafletUserConfig, setLeafletUserConfig] = useState<LeafletUserConfig>()

    /**Configuring the classes on the map */
    useEffect(()=>{

        function configMap () {
            if(map!==undefined && !minimapAdded && sessionUser !== undefined){

                /**Add minimap on the map! */
                const osm = new L.TileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {minZoom: 0, maxZoom: 13});
                new L.Control.MiniMap(osm, minimapOptions).addTo(map); 

                /**Add graticule on the map! */
                L.control.betterscale({ maxWidth: 200, metric: true, imperial:false }).addTo(map);

                /** Add legend on the map */
                new LeafletLegend(map, 'topright');

                /**Add layers controller */
                new LayersControllerClass(map);

                setLeafletUserConfig(new LeafletUserConfig(map, sessionUser));

                setMinimapAdded(true);

            }
        }

        configMap();

    }, [sessionUser, map, minimapAdded, user?.accessToken])

    const ZoomByWindow = useCallback(({boundsRectangle}:RectangleZoomProps) => {

        if(start && boundsRectangle !==undefined && initialPoint !== undefined){
            return (
            <Pane name="blue-rectangle" style={{ zIndex: 1000 }}>
                <Rectangle bounds={[[initialPoint?.getSouthWest().lat, initialPoint?.getSouthWest().lng],
                            [boundsRectangle?.getNorthEast().lat, boundsRectangle?.getNorthEast().lng]]} pathOptions={{ color: 'blue' }} />
            </Pane>
            )
        }else{
            return(<></>)
        }
    }, [initialPoint, start])

    const [openFilter, setOpenFilter] = useState(false);

    const toggleFilter = () => {
        setOpenFilter(!openFilter)
    }

    const [openFiles, setOpenFiles] = useState(false);

    const toggleFiles = () => {
        setOpenFiles(!openFiles)
    }

    return (
        <>
            <Styled.Container>
                <Styled.Map>
                    <MapContainer
                        center={[0, 0]} 
                        zoom={0} 
                        scrollWheelZoom={true}
                        style={{
                            height: '100%',
                            cursor: 'crosshair'
                        }}
                        {...contextMenu}
                        whenCreated={handleStartMap}
                    >
                        <Info />
                        <ZoomByWindow 
                            boundsRectangle={rectangle}
                        />
                    </MapContainer>
                </Styled.Map>
                <Toolbar
                    map={map}
                    toggleFilter = {toggleFilter}
                    toggleFiles = {toggleFiles}
                    setBoundsRectangle={setBoundsRectangle}
                    setStart={setStart}
                    setInitialPoint={setInitialPoint}
                    start={start}
                    initialPoint={initialPoint}
                />
            </Styled.Container>
            <Filter
                openDialog={openFilter}
                handleClose={toggleFilter}
                map={map}
                toggleFilter={leafletUserConfig?.toggleFilter}
            />
            <DialogFiles
                open={openFiles}
                map={map}
                toggleFiles={toggleFiles}
            />
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Download Shapefile
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the layer that you want to download by bouding box:
                    </DialogContentText>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="lands" control={<Radio />} label="Lands" />
                        <FormControlLabel value="glebes" control={<Radio />} label="Glebes" />
                        <FormControlLabel value="regfund" control={<Radio />} label="Lands Regularization" />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={()=>setOpenDialog(false)}>
                    Cancel
                </Button>
                <Button onClick={handleClose}>Download</Button>
                </DialogActions>
            </Dialog>
            <Footer 
                map={map}
                open={open}
            />
        </>
    )

}